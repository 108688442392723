import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import MembersView from '../views/MembersView.vue'
import ProjectsView from '../views/ProjectsView.vue'
import ReleaseView from '../views/ReleaseView.vue'
import FinishedProjectsView from '../views/FinishedProjectsView.vue'
import CalculatorView from '../views/CalculatorView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/members',
    name: 'Members',
    component: MembersView
  },
  {
    path: '/projects/:id',
    name: 'Projects',
    component: ProjectsView,
  },
  {
    path: '/projects/release/:id',
    name: 'Release',
    component: ReleaseView,
  },
  {
    path: '/finishedprojects',
    name: 'FinishedProjectsView',
    component: FinishedProjectsView
  },
  {
    path: '/calculator-module',
    name: 'CalculatorModule',
    component: CalculatorView
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
