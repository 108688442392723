<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <Home />
        <div class="contain-projects">
          <Loader v-if="loading"/>
          <CurrentProjects v-else/>
          <div class="title-finished-project d-flex">
            <h2>Nos projets terminés</h2>
            <div class="d-flex align-center">
              <span><a href="/finishedProjects">Voir tous</a></span>
            </div>
          </div>
          <Loader v-if="loading"/>
          <FinishedProjects v-else />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Home from '../components/Home'
import CurrentProjects from '../components/CurrentProjects.vue'
import FinishedProjects from '../components/FinishedProjects.vue'
import Loader from '../components/parts/Loader.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'HomeView',
  components: {
    Home,
    CurrentProjects,
    FinishedProjects,
    Loader,
  },
  data() {
    return {
      //
    }
  },
  mounted() {
    this.setIsSprintSliderInCards(true)
    this.setLoadingProject(true)
  },
  computed: {
    ...mapGetters(['getIsSprintSliderInCards', 'getLoadingProject']),
    isSprintSliderInCards() {
      return this.getIsSprintSliderInCards
    },
    loading() {
      return this.getLoadingProject
    },
  },
  methods: {
    ...mapActions([
      'setIsSprintSliderInCards',
      'setLoadingProject',
    ])
  }
}
</script>
<style scoped lang="scss">
.contain-projects {
  margin-top: 5rem;
}
.title-finished-project {
  margin-top: 3rem;
  h2 {
    color: #f1f1f1;
    font-weight: 500;
    letter-spacing: -0.5px;
    margin-bottom: 1rem;
    margin-left: 1rem;
  }
  span {
    margin-left: 1rem;
    font-size: 0.7rem;
    text-decoration: underline;
    padding-bottom: 1rem;
    a {
      font-weight: 500;
      color: #9c96a4;
    }
  }
}

@media screen and (max-width: 960px) {
  .contain-projects {
    margin-left: 0rem !important;
  }
}
</style>
