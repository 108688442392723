import Vue from 'vue'

const releaseStore = {
  state: {
    listReleases: [],
    release: {
      daily_periodicity: 1,
      date_end: '',
      date_start: '',
      duration_delivery: 0.25,
      duration_demo: 0.25,
      duration_plannif: 0.25,
      duration_retro: 0.125,
      duration_daily: 0.03,
      duration_sprint: 1,
      id_projects_gitlab: [],
      label: '',
      nb_sprint: 1,
      project_id: 0,
      sm_id: 0,
    },
    openDialogRelease: false,
    isReleaseEditing: false,
    idReleaseEditing: null,
    listParticipants: [],
    gitlabNameProjectList: [],
    versionGitlabProject: '',
  },
  getters: {
    getListReleases: (state) => state.listReleases,
    getRelease: (state) => state.release,
    getOpenDialogRelease: (state) => state.openDialogRelease,
    getReleaseEditing: (state) => state.isReleaseEditing,
    getIdReleaseEditing: (state) => state.idReleaseEditing,
    getGitlabNameProjectList: (state) => state.gitlabNameProjectList,
    getVersionGitlabProject: (state) => state.versionGitlabProject,
  },
  mutations: {
    SET_LIST_RELEASES(state, listReleases) {
      state.listReleases = listReleases
    },
    ADD_RELEASE(state, newRelease) {
      state.listReleases.push({ ...newRelease })
    },
    SET_RELEASE(state, release) {
      state.release = release
    },
    SET_OPEN_DIALOG_RELEASE(state, openDialogRelease) {
      state.openDialogRelease = openDialogRelease
    },
    SET_RELEASE_EDITING(state, isReleaseEditing) {
      state.isReleaseEditing = isReleaseEditing
    },
    SET_ID_RELEASE_EDITING(state, idReleaseEditing) {
      state.idReleaseEditing = idReleaseEditing
    },
    REMOVE_RELEASE(state, idRelease) {
      let idRemoveRelease = null
      state.listReleases.forEach((item, id) => {
        if (item.id == idRelease) {
          idRemoveRelease = id
        }
      })
      state.listReleases.splice(idRemoveRelease, 1)
    },
    // SET_LIST_PARTICIPANTS(state, listParticipants) {
    //   state.listParticipants = listParticipants
    // },
    SET_GITLAB_NAME_PROJECT_LIST(state, gitlabNameProjectList) {
      state.gitlabNameProjectList = gitlabNameProjectList
    },
    SET_VERSION_GITLAB_PROJECT(state, version) {
      state.versionGitlabProject = version
    },

  },
  actions: {
    // eslint-disable-next-line
    loadListReleases(store) {
      return Vue.axios
        .get(process.env.VUE_APP_API_ROOT + 'releases')
        .then((response) => {
          this.commit('SET_LIST_RELEASES', response.data)
          return Promise.resolve(response.data)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    createRelease(store, newRelease) {
      let token = Vue.$cookies.get('access_token')
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      }
      return Vue.axios
        .post(process.env.VUE_APP_API_ROOT + 'releases', newRelease, config)
        .then((response) => {
          this.commit('ADD_RELEASE', response.data)
          this.commit('SET_RELEASE', response.data)
          return Promise.resolve(response.data)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    updateRelease(store, release) {
      let token = Vue.$cookies.get('access_token')
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      }
      return Vue.axios
        .patch(process.env.VUE_APP_API_ROOT + 'releases/' + release.id, release, config)
        .then(() => {
          this.commit('SET_RELEASE', release)
          return Promise.resolve(release)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    loadRelease(store, releaseId) {
      return Vue.axios
        .get(process.env.VUE_APP_API_ROOT + 'releases/' + releaseId)
        .then((response) => {
          this.commit('SET_RELEASE', response.data)
          return Promise.resolve(response.data)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    removeRelease(store, id) {
      let token = Vue.$cookies.get('access_token') 
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      }
      return Vue.axios
        .delete(process.env.VUE_APP_API_ROOT + 'releases/' + id, config)
        .then(() => {
          this.commit('REMOVE_RELEASE', id)
          return Promise.resolve(id)
        })
        .catch((error) => {
          return Promise.reject(error)
        })

    },
    setOpenDialogRelease(store, openDialogRelease) {
      this.commit('SET_OPEN_DIALOG_RELEASE', openDialogRelease)
    },
    setReleaseEditing(store, isReleaseEditing) {
      this.commit('SET_RELEASE_EDITING', isReleaseEditing)
    },
    setIdReleaseEditing(store, idReleaseEditing) {
      this.commit('SET_ID_RELEASE_EDITING', idReleaseEditing)
    },
    loadListParticipants(store, releaseId) {
      return Vue.axios
        .get(process.env.VUE_APP_API_ROOT + 'releases/' + releaseId + '/participants')
        .then((response) => {
          // this.commit('SET_LIST_PARTICIPANTS', response.data)
          return Promise.resolve(response.data)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    fetchGitlabNameProjectList(store, releaseId) {
      return Vue.axios
      .get(process.env.VUE_APP_API_ROOT + 'releases/info/' + releaseId)
      .then((response) => {
        this.commit('SET_GITLAB_NAME_PROJECT_LIST', response.data)
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
    },
    fetchVersionGitlabProject(store, gitlabProjectId) {
      return Vue.axios
      .get(process.env.VUE_APP_API_ROOT + 'gitlabs/project/' + gitlabProjectId + '/tags')
      .then((response) => {
        this.commit('SET_VERSION_GITLAB_PROJECT', response.data)
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
    }

  },
}

export default releaseStore