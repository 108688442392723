<template>
  <v-container id="contain-projects-finished" fluid>
    <v-row>
      <v-col cols="12" md="12" lg="12">
        <div class="d-flex align-start flex-column">
          <a href="/">Retour</a>
          <h2>Projets terminés</h2>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12"
        md="12"
        lg="12"
        class="pa-0">
        <Loader v-if="loading"/>
        <FinishedProjects v-else/>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import { mapActions, mapGetters } from 'vuex'
import FinishedProjects from '../components/FinishedProjects.vue'
import Loader from '../components/parts/Loader.vue'

export default {
  name: 'FinishedProjectsView',
  components: {
    FinishedProjects,
    Loader,
  },
  computed: {
    ...mapGetters([
      'getLoadingProject'
    ]),
    loading() {
      return this.getLoadingProject
    }
  },
  mounted() {
    this.setLoadingProject(true) 
  },
  methods: {
    ...mapActions([
      'setLoadingProject'
    ])
  }
}
</script>

<style scoped lang="scss">
#contain-projects-finished {
  h2 {
    color: #ffc12b;
    font-weight: 400;
    letter-spacing: -0.5px;
  }
  a {
    color: #9e9e9e;
    font-size: 0.7rem;
    margin-bottom: 2rem;
  }
}

</style>