<template>
  <v-container v-if="projectWithGlNames" fluid>
    <v-row>
      <v-col>
        <div id="contain-project-page" class="d-flex justify-space-between">
          <div class="d-flex align-start flex-column">
            <a href="/">Retour</a>
            <h2>
              {{ projectWithGlNames.label }}
            </h2>
          </div>
          <v-btn
            v-if="isArchived"
            elevation="1"
            rounded
            @click="createRelease()"
          ><v-icon>mdi-plus-circle</v-icon>ajouter une nouvelle release</v-btn>
        </div>
      </v-col>
    </v-row>
    <Loader v-if="loading"/>
    <div v-else>
      <v-row v-for="release in projectWithGlNames.releases" :key="release.id">
        <v-col cols="12"
          lg="12"
          md="12"
          sm="12">
          <div id="contain-card">
            <v-row class="d-flex justify-center">
              <v-col cols="12"
                lg="12"
                md="12"
                sm="12">
                <v-card color="#160A28"
                  dark
                  elevation="3"
                  rounded>
                  <v-card-title class="d-flex justify-space-between align-start">
                    <div class="d-flex flex-column">
                      <!-- <h3>Titre</h3> -->
                      <h6 v-if="release.is_archived">
                        {{ release.id + ' - ' + release.label }} - Release terminée
                      </h6>
                      <h6 v-else>
                        {{ release.id + ' - ' + release.label }} - Release en cours
                      </h6>
                      <span v-if="release.date_start && release.date_end">
                        Du {{ release.date_start | moment("DD-MM-YYYY") }} au
                        {{ release.date_end | moment("DD-MM-YYYY") }}
                      </span>
                      <span v-else>
                        Dates non renseignés
                      </span>
                    </div>
                    <!-- chevron button -->
                    <v-btn
                      v-if="!release.is_archived"
                      icon
                      @click="openCard = !openCard"
                    >
                      <v-icon>{{
                        openCard ? "mdi-chevron-up" : "mdi-chevron-down"
                      }}</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text class="d-flex justify-start flex-column">
                    <v-row>
                      <v-col
                        cols="12"
                        class="d-flex justify-space-between"
                        :class="{ 'flex-column': !$vuetify.breakpoint.mdAndUp }"
                      >
                        <div class="d-flex flex-column">
                          <!-- <p>
                            <span>Durée d'un sprint :</span>
                            {{ release.duration_sprint }} semaines
                          </p> -->
                          <p v-if="release.sm">
                            <span>Scrum master :</span> {{ release.sm.fullname }}
                          </p>
                          <p v-else>
                            <span>Scrum master :</span> <span class="grey--text">Non renseigné</span>
                          </p>
                          <p>
                            <span>Projets GitLab associés : </span
                            >
                            <v-chip
                              v-for="gitLabLabel in release.gitlab_projects"
                              :key="gitLabLabel.id"
                              small
                            >
                              {{ gitLabLabel.project_name }}
                            </v-chip>
                          </p>
                        <!-- component slider -->
                        </div>
                        <v-row class="sprint-slider d-flex justify-center">
                          <v-col
                            cols="12"
                            lg="8"
                            md="12"
                            sm="12"
                            :class="{ 'mt-12': !$vuetify.breakpoint.mdAndUp }"
                          >
                            <SprintSlider :release-project="release" />
                            <div
                              v-if="!btnInitialized"
                              class="mt-4 d-flex justify-center"
                            >
                              <a
                                v-if="
                                  release.is_archived
                                    ? btnInitialized
                                    : !btnInitialized
                                "
                                class="btn-initialize-sprint"
                                @click="initializeSprint(release.id)"
                              >Cliquez ici pour initialiser un sprint</a
                              >
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-expand-transition v-if="!release.is_archived">
                      <div v-show="openCard">
                        <v-card-text class="px-0">
                          <div class="settings d-flex align-start">
                            <v-btn icon @click="openParamCard = !openParamCard">
                              <v-icon>{{
                                openParamCard
                                  ? "mdi-chevron-up"
                                  : "mdi-chevron-down"
                              }}</v-icon>
                            </v-btn>
                            <h3>Paramètres des temps SCRUM</h3>
                          </div>
                          <div v-show="openParamCard" class="settings">
                            <p>
                              <span
                              >Périodicity des daily stand up meeting :</span
                              >
                              {{ release.daily_periodicity }}
                            </p>
                            <p>
                              <span> Durée des daily stand up meeting : </span>
                              {{ release.duration_daily }}
                            </p>
                            <p>
                              <span>Durée de la livraison du projet :</span>
                              {{ release.duration_delivery }}
                            </p>
                            <p>
                              <span>Durée de la planification :</span>
                              {{ release.duration_plannif }}
                            </p>
                            <p>
                              <span>Durée de la démonstration :</span>
                              {{ release.duration_demo }}
                            </p>
                            <p>
                              <span>Durée de la rétrospective :</span>
                              {{ release.duration_retro }}
                            </p>
                          </div>
                        </v-card-text>
                      </div>
                    </v-expand-transition>
                  </v-card-text>
                  <div class="d-flex justify-end">
                    <v-card-actions class="mt-4">
                      <v-btn
                        v-if="!release.is_archived"
                        small
                        text
                        color="#8B829B"
                        @click="confirmArchive(release.id)"
                      >
                        archiver la release
                      </v-btn>
                      <v-btn
                        v-if="!release.is_archived"
                        text
                        small
                        color="#4AD295"
                        @click="editRelease(release.id)"
                      >
                        modifier
                      </v-btn>
                      <v-btn
                        v-if="release.is_archived"
                        text
                        small
                        color="#FF7B88"
                        @click="confirmDelete(release.id)"
                      >
                        supprimer
                      </v-btn>
                    </v-card-actions>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </div>

    <!-- dialog confirm suppression -->
    <v-dialog v-model="dialogDeleteReleaseConfirmation" max-width="650">
      <div class="dialog-release">
        <v-row class="d-flex justify-center align-center">
          <v-col
            cols="12"
            sm="11"
            md="11"
            class="d-flex flex-column justify-center align-center"
          >
            <p>VOULEZ-VOUS SUPPRIMER CETTE RELEASE ?</p>

            <div
              class="d-flex justify-center align-center footer-dialog-project"
            >
              <div class="d-flex">
                <v-btn color="#FF7B88"
                  plain
                  @click="cancelDelete()"
                >annuler</v-btn
                >
                <v-btn color="#4ad295"
                  plain
                  @click="deleteRelease()"
                >confirmer</v-btn
                >
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-dialog>

    <!-- Dialog confirm archive -->
    <v-dialog v-model="dialogArchiveReleaseConfirmation" max-width="650">
      <div class="dialog-release">
        <v-row class="d-flex justify-center align-center">
          <v-col
            cols="12"
            sm="11"
            md="11"
            class="d-flex flex-column justify-center align-center"
          >
            <p class="white--text">
              VOULEZ-VOUS ARCHIVER CETTE RELEASE ?
            </p>

            <div
              class="d-flex justify-center align-center footer-dialog-project"
            >
              <div class="d-flex">
                <v-btn color="#FF7B88"
                  plain
                  @click="cancelArchive()"
                >annuler</v-btn
                >
                <v-btn color="#4ad295"
                  plain
                  @click="archivedRelease()"
                >confirmer</v-btn
                >
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-dialog>
    <ReleasesForm :id="idReleaseEditing" :load-datas="loadDatas" />
    <SprintsForm v-if="openModalSprints" :id="idReleaseEditing" :load-datas="loadDatas" />
  </v-container>
</template>
<script>
import ReleasesForm from '../components/forms/ReleasesForm.vue'
import SprintSlider from '../components/SprintSlider.vue'
import SprintsForm from '../components/forms/SprintsForm.vue'
import Loader from '@/components/parts/Loader.vue'

import { mapActions, mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  name: 'ProjectDetails',
  components: {
    ReleasesForm,
    SprintSlider,
    SprintsForm,
    Loader,
  },
  data() {
    return {
      openCard: false,
      openParamCard: false,
      dialogDeleteReleaseConfirmation: false,
      dialogArchiveReleaseConfirmation: false,
      btnInitialized: true,
      isArchived: true,
      loading: false,
      releaseArchived: {
        id: null,
      },
    }
  },
  mounted() {
    this.setIsSprintSliderInCards(true)
    this.loadDatas()
  },
  computed: {
    ...mapGetters([
      'getRelease',
      'getListReleases',
      'getActualProject',
      'getActualProjectWithGlName',
      'getGitLab',
      'getOpenDialogRelease',
      'getReleaseEditing',
      'getIdReleaseEditing',
      'getOpenDialogSprints',
      'getLoader',
      'getGitlabNameProjectList',
    ]),

    actualRelease() {
      return this.getRelease
    },
    listReleases() {
      return this.getListReleases
    },
    project() {
      return this.getActualProject
    },
    projectWithGlNames() {
      return this.getActualProjectWithGlName
    },
    gitLabList() {
      return this.getGitLab
    },
    openDialogRelease() {
      return this.getOpenDialogRelease
    },
    editingRelease() {
      return this.getReleaseEditing
    },
    idReleaseEditing() {
      return this.getIdReleaseEditing
    },
    openModalSprints() {
      return this.getOpenDialogSprints
    },
    isLoading() {
      return this.getLoader
    },
    gitlabNameProjectList() {
      return this.getGitlabNameProjectList
    },
  },
  methods: {
    ...mapActions([
      'loadRelease',
      'loadProject',
      'loadGitLabList',
      'setOpenDialogRelease',
      'setReleaseEditing',
      'setIdReleaseEditing',
      'removeRelease',
      'openDialogSprints',
      'loadMilestonesList',
      'setLoader',
      'updateRelease',
      'setIsSprintSliderInCards',
      'fetchGitlabNameProjectList',
      'setNewProjectWithGlName'
    ]),
    loadDatas() {
      this.loading = true
      this.loadProject(this.$route.params.id)
      .then((response) => {
        // Create newResponse + reset releases
        const newResponse = _.clone(response)
        newResponse.releases = []
        // Get gitlab names for each release
        response.releases.forEach((release) => {
          const newRelease = _.clone(release)
          if (release.id_projects_gitlab) {
            // Fetch each gitlab project name linked to each release
            this.fetchGitlabNameProjectList(release.id)
              .then((responseGlName) => {
                // inject gitlab project name into current release
                newRelease.gitlab_projects = responseGlName
                // Push modify release with GL names
                newResponse.releases.push(newRelease)
                this.loading = false
              })
              .catch((error) => {
                console.log(error)
              })
          } else {
            newResponse.releases.push(newRelease)
          }

          // recup id release a modifier et la charger dans actual release
          if (release.is_archived === false) {
            this.isArchived = true
            this.btnInitialized = false
            // ! FIXME: A vérifier
            // this.loadRelease(release.id)
            //   .then(() => {
            //     this.btnInitialized = false;
            //   })
            //   .catch((error) => {
              //     console.log(error);
            //   });
          }
        })
        // Set new actuel project
        this.setNewProjectWithGlName(newResponse)
      })
      .catch((error) => {
        this.loading = false
        console.log(error)
      })
    },
    editRelease(id) {
      this.setReleaseEditing(true)
      this.setIdReleaseEditing(id)
      this.setOpenDialogRelease(true)
    },
    createRelease() {
      this.setReleaseEditing(false)
      this.setIdReleaseEditing(null)
      this.setOpenDialogRelease(true)
    },
    confirmDelete(id) {
      this.setIdReleaseEditing(id)
      this.dialogDeleteReleaseConfirmation = true
    },
    cancelDelete() {
      this.dialogDeleteReleaseConfirmation = false
    },
    confirmArchive(id) {
      this.setIdReleaseEditing(id)
      this.dialogArchiveReleaseConfirmation = true
    },
    cancelArchive() {
      this.dialogArchiveReleaseConfirmation = false
    },
    deleteRelease() {
      this.removeRelease(this.idReleaseEditing)
        .then(() => {
          this.$awn.success('Release supprimée avec succès.', {})
          this.loadDatas()
          this.dialogDeleteReleaseConfirmation = false
        })
        .catch((error) => {
          this.$awn.alert(
            'Une erreur est survenue lors de la suppression.',
            {}
          )
          console.log(error)
        })
    },
    initializeSprint(id) {
      this.openDialogSprints(true)
      this.setIdReleaseEditing(id)
      this.setReleaseEditing(true)
      this.loadMilestonesList(this.project.id)
        .then(() => {
          //
        })
        .catch((error) => {
          console.log(error)
        })
    },
    archivedRelease() {
      this.releaseArchived.id = this.idReleaseEditing
      this.updateRelease({ id: this.idReleaseEditing, is_archived: true })
        .then(() => {
          this.dialogArchiveReleaseConfirmation = false
          this.loadDatas()
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
<style lang="scss" scoped>
#contain-release {
  margin-top: 3rem;

  a {
    color: #9e9e9e;
    font-size: 0.7rem;
    margin-bottom: 2rem;
  }
  h2 {
    color: #ffc12b;
    font-weight: 500;
    letter-spacing: -0.5px;
    font-size: 1.4rem;
  }

  .v-btn {
    margin-right: 3rem;
    border: 2px solid #4ad295;
    background: transparent;
    color: #4ad295;
    font-size: 0.6rem;
    font-weight: 600;
    letter-spacing: -0.5px;
    .v-icon {
      margin-right: 0.5rem;
      color: #4ad295;
      font-size: 1.2rem;
    }
  }
}
#contain-project-page {
  margin-top: 3rem;

  a {
    color: #9e9e9e;
    font-size: 0.7rem;
    margin-bottom: 2rem;
  }
  h2 {
    color: #ffc12b;
    font-weight: 500;
    letter-spacing: -0.5px;
    font-size: 1.4rem;
  }

  .v-btn {

    border: 2px solid #4ad295;
    background: transparent;
    color: #4ad295;
    font-size: 0.6rem;
    font-weight: 600;
    letter-spacing: -0.5px;
    .v-icon {
      margin-right: 0.5rem;
      color: #4ad295;
      font-size: 1.2rem;
    }
  }
}
#contain-card {
  margin-top: 2rem;

  .v-card {
    padding: 1rem;
    border-radius: 12px !important;
  }
  .v-card__title {
    color: #ffde8d;
    padding-bottom: 0;
    h3 {
      font-weight: 500;
      letter-spacing: -0.5px;
      font-size: 1.4rem;
    }
    h6 {
      font-weight: 400;
      letter-spacing: -0.5px;
      font-size: 1.2rem;
      margin-bottom: -0.5rem;
    }
    span {
      color: #9e9e9e;
      font-size: 0.7rem;
      margin-bottom: 2rem;
      font-style: italic;
    }
  }
  .mdi-chevron-up,
  .mdi-chevron-down {
    color: #ffc12b;
  }
  .v-card__text {
    color: #f2f2f2;
    p {
      margin-bottom: 0.2rem;
      font-weight: 300;
      span {
        font-weight: 600;
      }
    }
    .theme--dark.v-chip:not(.v-chip--active) {
      background: rgb(56, 248, 203);
      background: linear-gradient(
        180deg,
        rgba(56, 248, 203, 1) 0%,
        rgba(74, 210, 149, 1) 100%
      );
      color: #150041;
      margin: 0 0.5rem;
    }
    .settings {
      h3 {
        font-weight: 400;
        letter-spacing: -0.5px;
        font-size: 1.1rem;
        margin-left: 0.5rem;
        margin-bottom: 1.5rem;
        margin-top: 0.5rem;
      }
      p {
        margin-bottom: 0.2rem;
        font-weight: 200;
        span {
          font-weight: 400;
        }
      }
    }
  }
  #slide-container {
    .sprint {
      margin-bottom: -0.5rem !important;
    }
  }
  .sprint-slider {
    margin-top: -2.5rem;
  }
  .btn-initialize-sprint {
    color: #ffde8d;
    font-size: 0.7rem;
    font-style: italic;
    font-weight: 300;
    margin: 4rem 0 3rem 0;
  }
}
.dialog-release {
  display: flex;
  flex-direction: column;
  height: 50%;
  background-color: #241738;
  padding: 1rem;
  h2 {
    margin-bottom: 2rem;
    color: #ffc12b;
    font-weight: 500;
    letter-spacing: -0.5px;
    font-size: 1.4rem;
  }
  h3 {
    margin-bottom: 2rem;
    color: #ffc12b;
    font-weight: 500;
    letter-spacing: -0.5px;
    font-size: 2rem;
  }

  p {
    color: #ff7b88;
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 1rem;
  }
}
@media screen and (max-width: 960px) {
  #contain-release,
  #contain-project-page {
    margin-left: 2rem;
    h2 {
      margin-left: 2rem;
    }
    a {
      margin-left: 2rem;
    }
  }
  #contain-card {
    margin-left: 4rem;
    .sprint-slider {
      margin-top: 2rem;
    }
  }
}
@media screen and (max-width: 600px) {
  #contain-release,
  #contain-project-page {
    margin-left: 1rem;
    h2 {
      margin-left: 1rem;
      font-size: 1rem;
    }
    a {
      margin-left: 1rem;
    }
  }
  #contain-card {
    margin-left: 2rem;
    margin-right: 2rem;

    .sprint-slider {
      margin-top: 2rem;
    }
  }
}
</style>
