import Vue from 'vue'

const memberStore = {
  state: {
    listMembers: [],
    member: {
      fullname: '', 
      id_gitlab: '',
      job: '',
      trigram: '',
    },
    gitlabUsersList: [],
    showMembersForm: false,
    isMemberEditing: false,

  },
  getters: {
    getListMembers: (state) => state.listMembers,
    getMember: (state) => state.member,
    getGitlabUsersList: (state) => state.gitlabUsersList,
    getShowMembersForm: (state) => state.showMembersForm,
    getIsMemberEditing: (state) => state.isMemberEditing,
  },
  mutations: {
    SET_LIST_MEMBERS(state, listMembers) {
      state.listMembers = listMembers
    },
    ADD_MEMBER(state, newMember) {
      state.listMembers.push({ ...newMember })
    },
    SET_MEMBER(state, member) {
      state.member = member
    },
    REMOVE_MEMBER(state, idMember) {
      let idRemoveMember = null
      state.listMembers.forEach((item, id) => {
        if(item.id == idMember) {
          idRemoveMember = id
        }
      })
      state.listMembers.splice(idRemoveMember, 1)
    },
    SET_GITLAB_USERS_LIST(state, gitlabUsersList) {
      state.gitlabUsersList = gitlabUsersList
    },
    SET_SHOW_MEMBERS_FORM(state, showDialog) {
      state.showMembersForm = showDialog
    },
    SET_IS_MEMBER_EDITING(state, isMemberEditing) {
      state.isMemberEditing = isMemberEditing
    },
  },
  actions: {
    // eslint-disable-next-line
    loadListMembers(store) {
      return Vue.axios
      .get(process.env.VUE_APP_API_ROOT +'users')
      .then((response) => {
        this.commit('SET_LIST_MEMBERS', response.data)
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
    },
    createMember(store, newMember) {
      let token = Vue.$cookies.get('access_token') 
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      }
      return Vue.axios
      .post(process.env.VUE_APP_API_ROOT +'users', newMember, config)
      .then((response) => {
        this.commit('ADD_MEMBER', response)
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
    },
    deleteMember(store, id) {
      let token = Vue.$cookies.get('access_token')
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      }
      return Vue.axios
      .delete(process.env.VUE_APP_API_ROOT +'users/'+ id, config)
      .then(() => {
        this.commit('REMOVE_MEMBER', id)
        return Promise.resolve(id)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
    },
    updateMember(store, member) {
      let token = Vue.$cookies.get('access_token')
      console.log(token)
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      }
      return Vue.axios
      .patch(process.env.VUE_APP_API_ROOT +'users/'+ member.id, member, config)
      .then(() => {
        this.commit('SET_MEMBER', member) 
        return Promise.resolve(member)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
    },
    loadMember(store, id) {
      return Vue.axios
      .get(process.env.VUE_APP_API_ROOT +'users/'+ id)
      .then((response) => {

        this.commit('SET_MEMBER', response.data)
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
    },
    // eslint-disable-next-line
    fetchGitlabUsersList(store) {
      return Vue.axios
      .get(process.env.VUE_APP_API_ROOT + 'gitlabs/users')
      .then((response) => {
        this.commit('SET_GITLAB_USERS_LIST', response.data)
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
    },
    setShowMembersForm(store, showDialog) {
      this.commit('SET_SHOW_MEMBERS_FORM', showDialog)
    },
    setIsMemberEditing(store, isMemberEditing) {
      this.commit('SET_IS_MEMBER_EDITING', isMemberEditing)
    },
  },
}

export default memberStore