<template>
  <v-dialog v-model="openModalSprints" persistent max-width="800">
    <div v-if="currentSprint && actualRelease" class="dialog-sprints">
      <v-row class="d-flex justify-center align-content-space-between">
        <v-col cols="12" sm="11" md="11">
          <!-- form -->
          <v-form ref="form_sprint_valid" v-model="formSprintValid">
            <div class="mb-4">
              <a @click="backward()">Retour</a>
              <h2 v-if="sprintEditing">
                Modification d'un sprint
              </h2>
              <h2 v-else>
                Initialisation d'un sprint
              </h2>
              <h3 v-if="actualRelease.project">
                Projet : {{ actualRelease.project.label }}
              </h3>
              <!-- Durations -->
              <v-row class="mt-6">
                <v-col cols="12" md="6" lg="6">
                  <v-text-field v-model="sprintForm.duration_sprint"
                    type="number"
                    dark
                    dense
                    :rules="[rules.required]"
                    label="Durée du sprint : (semaines) *"/>
                </v-col>
                <v-col cols="12" md="6" lg="6">
                  <v-text-field v-model="sprintForm.duration_days"
                    type="number"
                    dark
                    disabled
                    dense
                    :rules="[rules.required]"
                    label="Durée du sprint : (jours ouvrés) *"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12" lg="12">
                  <v-text-field v-model="sprintForm.time_unavailability"
                    type="number"
                    dark
                    dense
                    label="Indisponibilité de l'équipe complète : (jours)"/>
                </v-col>
              </v-row>
              <!-- Date picker -->
              <v-row>
                <v-col cols="12"
                  lg="6"
                  md="6"
                  sm="6"
                  id="date-picker">
                  <v-text-field
                    v-model="sprintForm.date_start"
                    label="Date de début *"
                    type="date"
                    :disabled="sprintEditing"
                    max="2300-01-01"
                    dark
                    :rules="[rules.required]"
                    class="input-edit-add"
                  />
                </v-col>
                <v-col cols="12"
                  lg="6"
                  md="6"
                  sm="6">
                  <v-text-field
                    v-model="sprintForm.date_end"
                    label="Date de fin *"
                    type="date"
                    disabled
                    max="2300-01-01"
                    dark
                    :rules="[rules.required]"
                    class="input-edit-add"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12" lg="12">
                  <v-select
                    dark
                    :menu-props="{ dark: true }"
                    v-model="sprintForm.milestones_git"
                    :items="milestonesList"
                    item-text="label"
                    item-value="id"
                    label="Selectionner le ou les milestone(s) Gitlab associés"
                    multiple
                    dense
                    chips
                  />
                  <p v-if="milestonesList.length == 0" class="milestonesMessage">
                    La release ne possède pas de milestones
                  </p>
                </v-col>
              </v-row>
            </div>
            <!-- scrum times -->
            <div class="mb-8">
              <h4>
                Paramètres avancés SCRUM
                <p>
                  Vérifiez les paramètres ci-dessous, les temps à renseigner sont en
                  heure
                </p>
              </h4>
              <v-row>
                <v-col cols="12" md="6" lg="6">
                  <span class="subtitle"
                  >Périodicité des dailys stand up meeting</span
                  >
                  <v-text-field
                    dark
                    dense
                    type="number"
                    step="0.01"
                    v-model="sprintForm.daily_periodicity"
                  />
                </v-col>
                <v-col cols="12"
                  lg="6"
                  md="6"
                  sm="6">
                  <span class="subtitle">Durée des dailys stand up meeting</span>
                  <v-text-field
                    dark
                    dense
                    v-model="sprintForm.duration_daily"
                    type="number"
                    step="0.01"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" lg="6">
                  <span class="subtitle">Durée des livraisons</span>
                  <v-text-field
                    dark
                    dense
                    type="number"
                    v-model="sprintForm.duration_delivery"
                    step="0.01"
                  />
                </v-col>
                <v-col cols="12"
                  lg="6"
                  md="6"
                  sm="6">
                  <span class="subtitle">Durée des démonstrations</span>
                  <v-text-field
                    dark
                    dense
                    type="number"
                    v-model="sprintForm.duration_demo"
                    step="0.01"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" lg="6">
                  <span class="subtitle">Durée des planifications</span>
                  <v-text-field
                    dark
                    dense
                    type="number"
                    v-model="sprintForm.duration_plannif"
                    step="0.01"
                  />
                </v-col>
                <v-col cols="12"
                  lg="6"
                  md="6">
                  <span class="subtitle">Durée des rétrospectives du sprint</span>
                  <v-text-field
                    dark
                    dense
                    type="number"
                    v-model="sprintForm.duration_retro"
                    step="0.01"
                  />
                </v-col>
              </v-row>
            </div>
            <!-- Team -->
            <h4 class="my-4">
              Equipe projet
            </h4>
            <!-- Table users -->
            <div v-if="userTimeList.length > 0" class="my-4">
              <v-data-table dense
                dark
                hide-default-footer
                :items="userTimeList"
                :headers="headers"
                class="elevation-1">
                <template #[`item.user`]="{ item }">
                  <v-chip small color="primary" class="black--text">
                    {{ item.user }}
                  </v-chip>
                </template>
                <template #[`item.actions`]="{ item }">
                  <v-btn icon
                    v-if="!sprintEditing"
                    color="red"
                    @click="removeUser(item)">
                    <v-icon>
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </div>
            <div class="sprint-team">
              <v-row>
                <v-col v-if="!sprintEditing"
                  cols="12"
                  lg="6"
                  md="6"
                  sm="6">
                  <v-select
                    dark
                    :menu-props="{ dark: true }"
                    v-model="sprintUser.user_id"
                    :items="listMembers"
                    item-text="fullname"
                    item-value="id"
                    label="Selectionner un utilisateur"
                  />
                </v-col>
                <v-col v-if="!sprintEditing"
                  cols="12"
                  lg="6"
                  md="6"
                  sm="6">
                  <v-text-field
                    dark
                    dense
                    label="Nombre de jours d'indisponibilité"
                    type="number"
                    v-model="sprintUser.unavailable_days"
                    class="mt-5"
                    :rules="[rules.required]"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col v-if="!sprintEditing"
                  cols="12"
                  lg="6"
                  md="6"
                  sm="6">
                  <v-text-field v-model="sprintUser.risk_rate"
                    type="number"
                    dark
                    dense
                    label="Taux de risque : (%)">
                    <!--  -->
                  </v-text-field>
                </v-col>
                <v-col v-if="!sprintEditing"
                  cols="12"
                  lg="6"
                  md="6"
                  sm="6">
                  <v-text-field v-model="sprintUser.availability_percent"
                    type="number"
                    dark
                    max="5"
                    dense
                    label="Temps alloué à la production : (jours)">
                    <!--  -->
                  </v-text-field>
                </v-col>
              </v-row>
              <div v-if="!sprintEditing" class="d-flex justify-center mt-12">
                <v-btn elevation="1"
                  rounded
                  @click="addUserSprint()"
                ><v-icon>mdi-plus-circle</v-icon>ajouter un membre sur ce
                  sprint</v-btn
                >
              </div>
            </div>
            <!--  -->
          </v-form>
        </v-col>
      </v-row>
      <!-- btn actions -->
      <v-row>
        <v-col cols="12"
          md="12"
          lg="12"
          class="d-flex justify-end align-center footer-dialog-releases mt-12">
          <div>
            <v-btn color="#FFDE8D"
              small
              text
              @click="cancelSprint()"
            >annuler</v-btn
            >
            <v-btn
              v-if="sprintEditing"
              color="#4AD295"
              small
              text
              :loading="isLoading"
              @click="editSprint()"
            >Modifier le sprint</v-btn
            >
            <v-btn
              v-else
              color="#4AD295"
              small
              text
              @click="addNewSprint()"
              :loading="isLoading"
            >calculer la capacité du sprint</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import _ from 'lodash'

export default {
  name: 'SprintsForm',
  props: {
    sprint: { type: Object, required: false },
    id: { type: Number, required: false, default: null },
    loadDatas: { type: Function, required: false, default: ()=>{} }
  },
  data() {
    return {
      formSprintValid: false,
      sprintForm: {
        date_end: '',
        date_start: '',
        milestones_git: [],
        release_id: this.id,
        duration_sprint: 0,
        duration_days: 0,
        time_unavailability: 0,
        daily_periodicity: 0,
        duration_daily: process.env.VUE_APP_DURATION_DAILY,
        duration_delivery: process.env.VUE_APP_DURATION_DELIVERY,
        duration_demo: process.env.VUE_APP_DURATION_DEMO,
        duration_plannif: process.env.VUE_APP_DURATION_PLANNIF,
        duration_retro: process.env.VUE_APP_DURATION_RETRO,
      },
      sprintUser: {
        unavailable_days: 0,
        availability_percent: 0,
        risk_rate: 0,
        sprint_id: null,
        user_id: null,
        capacity: 0,
        total: 0,
        total_without_scrum: 0,
      },
      listParticipants: [],
      userSprintAdd: false,
      isLoading: false,
      rules: {
        required: (value) => (!!value || value === 0) || 'Champs requis',
      },
      userTimeList: [],
      headers: [
        { text: '', value: 'user.trigram', sortable: null },
        { text: 'Indispo', value: 'unavailable_days', sortable: null },
        { text: '% de risque', value: 'risk_rate', sortable: null },
        { text: 'Tps production', value: 'availability_percent', sortable: null },
        { text: 'Total', value: 'total', sortable: null },
        { text: 'Total (- SCRUM)', value: 'total_without_scrum', sortable: null },
        { text: '', value: 'actions', sortable: null },
      ]
    }
  },
  mounted() {
    this.loadListMembers()
      .then(() => {})
      .catch((error) => {
        console.log(error)
      })
    this.loadRelease(this.id)
        .then(() => {
          this.sprintForm.release_id = this.actualRelease.id
          this.loadMilestonesList(this.actualRelease.project.id)
            .then(() => {})
            .catch((error) => {
              console.log(error)
            })  

        })
        .catch((error) => {
          console.log(error)
        })  
  },
  computed: {
    ...mapGetters([
      'getOpenDialogSprints',
      'getRelease',
      'getMilestones',
      'getActualProject',
      'getListMembers',
      'getListSprintUser',
      'getSprintEditing',
      'getSprint',
    ]),
    openModalSprints() {
      return this.getOpenDialogSprints
    },
    actualRelease() {
      return this.getRelease
    },
    milestonesList() {
      return this.getMilestones
    },
    project() {
      return this.getActualProject
    },
    listMembers() {
      return this.getListMembers
    },
    listSprintUser() {
      return this.getListSprintUser
    },
    sprintEditing() {
      return this.getSprintEditing
    },
    currentSprint() {
      return this.getSprint
    },
    sprintDuration() {
      return this.sprintForm.duration_sprint
    },
    sprintEndDate() {
      if (this.sprintForm.date_start) {
        let numberWeeks =  this.sprintDuration
        let dateEnd = new Date(this.sprintForm.date_start)
        dateEnd.setDate(dateEnd.getDate() + parseInt(numberWeeks) * 7)
        return dateEnd.toISOString().substring(0, 10)
      } else {
        return ''
      }
    },
    sumDays() {
      return parseFloat(this.sprintForm.daily_periodicity / 7).toFixed(3)
    }
  },
  watch: {
    openModalSprints() {
      if (this.openModalSprints && this.sprint) {
        this.sprintForm = _.clone(this.sprint)
        if (this.sprint.duration_sprint === null) {
          this.sprintForm.duration_sprint = 0
          this.sprintForm.duration_days = this.sprintForm.duration_sprint * 5
        }
        this.userTimeList = _.clone(this.sprint.users)
        this.userTimeList.forEach((user) => {
          let percent = parseFloat(user.risk_rate)
          let durationDays = this.sprint.duration_sprint * 5
          let unavailableDays = user.unavailable_days
          let timeUnavailability = this.sprint.time_unavailability
          let number = durationDays - unavailableDays - timeUnavailability
          user.total = number - ((percent * number) / 100)
        })
      } else {
        this.listParticipants = []
      }
    },

    sprintDuration() {
      if (this.sprintDuration) {
        this.sprintForm.duration_days = this.sprintForm.duration_sprint * 5
      } else {
        this.sprintForm.duration_days = 0
      }
    },
    sprintEndDate() {
      if (this.sprintDuration) {
        this.sprintForm.date_end = this.sprintEndDate
      }
    }
  },
  methods: {
    ...mapActions([
      'openDialogSprints',
      'loadMilestonesList',
      'createSprint',
      'loadRelease',
      'createSprintUser',
      'loadListMembers',
      'setSprintEditing',
      'loadSprint',
      'updateSprint',
      'loadProject',
      'calculateCapacity',
    ]),
    loadDatasRelease() {
      if (this.actualRelease) {
        this.loadRelease(this.actualRelease.id)
        .then(() => {})
        .catch((error) => {
          console.log(error)
        })
      }
    },
    backward() {
      this.openDialogSprints(false)
    },
    cancelSprint() {
      this.openDialogSprints(false)
      this.setSprintEditing(false)
      this.sprintForm = {
        date_end: '',
        date_start: '',
        milestones_git: [],
        release_id: this.id,
        duration_sprint: 0,
        duration_days: 0,
        time_unavailability: 0,
        daily_periodicity: 0,
        duration_daily: process.env.VUE_APP_DURATION_DAILY,
        duration_delivery: process.env.VUE_APP_DURATION_DELIVERY,
        duration_demo: process.env.VUE_APP_DURATION_DEMO,
        duration_plannif: process.env.VUE_APP_DURATION_PLANNIF,
        duration_retro: process.env.VUE_APP_DURATION_RETRO,
      },
      this.sprintUser = {
        unavailable_days: 0,
        availability_percent: 0,
        risk_rate: 0,
        sprint_id: null,
        user_id: null,
        capacity: 0,
        total: 0,
        total_without_scrum: 0,
      },
      this.userTimeList = []
      this.$refs.form_sprint_valid.resetValidation()
    },
    addNewSprint() {
      this.$refs.form_sprint_valid.validate()
      if (this.formSprintValid) {
        this.isLoading = true
        this.sprintForm.duration_delivery = parseFloat(this.sprintForm.duration_delivery)
        this.sprintForm.duration_demo = parseFloat(this.sprintForm.duration_demo)
        this.sprintForm.duration_plannif = parseFloat(this.sprintForm.duration_plannif)
        this.sprintForm.duration_daily = parseFloat(this.sprintForm.duration_daily)
        this.sprintForm.duration_retro = parseFloat(this.sprintForm.duration_retro)
        this.sprintForm.daily_periodicity = parseFloat(this.sumDays)
        this.createSprint(this.sprintForm)
          .then((responseSprint) => {
            // Add users to sprint
            this.createSprintUser({
              sprint_id: responseSprint.id,
              list_participants: this.userTimeList,
            })
            .then(() => {
              // Calculte the theoric capacity
              this.calculateCapacity(responseSprint.id)
                .then(() => {})
                .catch((error) => {
                console.log(error)
              })
              this.isLoading = false
              this.openDialogSprints(false)
              this.loadDatas()
              // Reload project
              this.loadProject(this.project.id)
              .then(() => {})
              .catch((error) => { console.log(error) })
              })
            .catch((error) => {
              this.isLoading = false
              this.$awn.alert("Une erreur est survenue lors de l'ajout des utilisateurs.",{})
              console.log(error)
            })
            this.$awn.success('Sprint initialisé avec succès.', {})
          })
          .catch((error) => {
            this.isLoading = false
            this.$awn.alert("Une erreur est survenue lors de l'initialisation'.",{})
            console.log(error)
          })
      } else {
        this.isLoading = false
        this.$awn.warning('informations incorrectes', {})
      }
    },
    addUserSprint() {
      if (this.sprintForm.duration_sprint) {
        let percent =  parseFloat(this.sprintUser.risk_rate)
        let number = (parseFloat(this.sprintForm.duration_days) -  parseFloat(this.sprintUser.unavailable_days) - parseFloat(this.sprintForm.time_unavailability))
        this.sprintUser.total = number - (( percent * number ) / 100)
        // Total without scrum
        let numberDaysReals = parseFloat(this.sprintForm.duration_days) - parseFloat(this.sprintForm.time_unavailability)
        let teamTimes = parseFloat(this.sprintForm.duration_delivery) + parseFloat(this.sprintForm.duration_demo) + parseFloat(this.sprintForm.duration_plannif) + (parseFloat(this.sumDays) * numberDaysReals)
        this.sprintUser.total_without_scrum = teamTimes.toFixed(2)
        // push user info for trigram
        const user = this.listMembers.find(user => user.id === this.sprintUser.user_id)
        this.sprintUser.user = user
        this.sprintUser.risk_rate = parseFloat(this.sprintUser.risk_rate)
        // calculate percent of availability_percent (actually in days)
        this.sprintUser.availability_percent = (parseFloat(this.sprintUser.availability_percent) * parseFloat(this.sprintForm.duration_sprint)) * 100 / parseFloat(this.sprintForm.duration_days)
        this.userTimeList.push(this.sprintUser)
        this.sprintUser = {
          unavailable_days: 0,
          availability_percent: 0,
          risk_rate: 0,
          sprint_id: null,
          user_id: null,
          capacity: 0,
          total: 0,
          total_without_scrum: 0,
        }
      } else {
        this.$awn.warning('Informations manquantes, durée du sprint requise', {})
      }
    },
    removeUser(item) {
      let userToRemove = this.userTimeList.findIndex(user => user == item)
      this.userTimeList.splice(userToRemove, 1)
    },
    rounded(value) {
      return Math.floor(value)
    },
    editSprint() {
      this.$refs.form_sprint_valid.validate()
      if (this.formSprintValid) {
        this.isLoading = true
        this.updateSprint(this.sprintForm)
          .then(() => {
            this.isLoading = false
            this.setSprintEditing(false)
            this.openDialogSprints(false)
            this.loadDatasRelease()
            this.$awn.success('Sprint modifié avec succès.', {})
          })
          .catch((error) => {
            this.isLoading = false
            this.$awn.alert(
              'Une erreur est survenue lors de la modification.',
              {}
            )
            console.log(error)
          })
      } else {
        this.isLoading = false
        this.$awn.warning('informations incorrectes', {})
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.v-dialog {
  .dialog-sprints {
    display: flex;
    flex-direction: column;
    height: 50%;
    background-color: #241738;
    padding: 1rem;
    h2 {
      color: #ffc12b;
      font-weight: 500;
      letter-spacing: -0.5px;
      font-size: 1.4rem;
    }
    h3 {
      margin-bottom: 2rem;
      color: #f2f2f2;
      font-weight: 500;
      letter-spacing: -0.5px;
      font-size: 1.1rem;
    }
    h4 {
      color: #f2f2f2;
      font-weight: 500;
      letter-spacing: -0.5px;
      font-size: 1.3rem;
    }
    a {
      color: #9e9e9e;
      font-size: 0.7rem;
    }
    p {
      color: #9e9e9e;
      font-size: 0.8rem;
      font-style: italic;
      font-weight: 300;
    }
    .subtitle {
      color: #ffde8d;
      font-weight: 300;
    }
    span {
      color: #f2f2f2;
      font-weight: 200;
      font-size: 0.9rem;
    }
    .pourcentSpan {
      margin-left: 0.6rem;
      color: #ffde8d;
    }
    .sprint-team {
      .v-btn {
        margin-right: 3rem;
        border: 2px solid #4ad295;
        background: transparent;
        color: #4ad295;
        font-size: 0.6rem;
        font-weight: 600;
        letter-spacing: -0.5px;
        .v-icon {
          margin-right: 0.5rem;
          color: #4ad295;
          font-size: 1.2rem;
        }
      }
    }
    .milestonesMessage {
      color: #ffde8d;
      position: relative;
      z-index: 50;
      margin-top: -1rem;
    }
    .footer-dialog-sprints {
      // margin-top: 8rem;
      .v-btn {
        letter-spacing: -0.5px;
      }
      .cancel-btn {
        color: #ffde8d;
        letter-spacing: -0.5px;
      }
      .create-btn {
        color: #4ad295;
        letter-spacing: -0.5px;
      }
      .archived-btn {
        color: #8b829b;
        letter-spacing: -0.5px;
        padding-left: 0;
      }
    }
    input::-webkit-calendar-picker-indicator {
      color: red !important;
    }
  }
}
.input-slider {
  margin-left: 1rem;
  max-width: 50px;
}
.theme--dark.v-sheet {
  background-color: #241738 !important;
}

.theme--dark {
  background-color: #241738 !important;
}

.theme--dark.v-chip:not(.v-chip--active) {
  background: rgb(56, 248, 203) !important;
  background: linear-gradient(
    180deg,
    rgba(56, 248, 203, 1) 0%,
    rgba(74, 210, 149, 1) 100%
  );
  color: #150041 !important;
  font-weight: 400;
}
</style>