import Vue from 'vue'

const commonStore = {
  state: {
    isLogged: false,
    token: '',
    isLoading: false,
    isSprintSliderInCards: false,
    isConsultRelease: false,
  },
  getters: {
    getLogged: (state) => state.isLogged,
    getToken: (state) => state.token,
    getLoader: (state) => state.isLoading,
    getIsSprintSliderInCards: (state) => state.isSprintSliderInCards,
    getIsConsultRelease: (state) => state.isConsultRelease,
  },
  mutations: {
    SET_LOGGED(state, isLogged) {
      state.isLogged = isLogged
    },
    SET_TOKEN(state, token) {
      state.token = token
    },
    SET_LOADER(state, isLoading) {
      state.isLoading = isLoading
    },
    SET_IS_SPRINT_SLIDER_IN_CARDS(state, isSprintSliderInCards) {
      state.isSprintSliderInCards = isSprintSliderInCards
    },
    SET_IS_CONSULT_RELEASE(state, isConsultRelease) {
      state.isConsultRelease = isConsultRelease
    },
  },
  actions: {
    setIsLogged(store, isLogged) {
      this.commit('SET_LOGGED', isLogged)
    },
    setToken(store, token) {
      this.commit('SET_TOKEN', token)
    },
    // eslint-disable-next-line
    loggin(store, password) {
      return Vue.axios
      .post(process.env.VUE_APP_API_ROOT +'users/login', password)
      .then((response) => {
          return Promise.resolve(response.data)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    setLoader(store, isLoading) {
      this.commit('SET_LOADER', isLoading)
    },
    loadDataBurndowns(store, sprintId) {
        return Vue.axios
        .get(process.env.VUE_APP_API_ROOT + 'datas_burndowns/sprint/' + sprintId)
        .then((response) => {
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error)
        })
    },
    loadRatesCards(store, sprintId) {
        return Vue.axios
        .get(process.env.VUE_APP_API_ROOT + 'sprints/' + sprintId + '/time_report')
        .then((response) => {
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error)
        })
    },
    setIsSprintSliderInCards(store, isSprintSliderInCards) {
      this.commit('SET_IS_SPRINT_SLIDER_IN_CARDS', isSprintSliderInCards)
    },
    setIsConsultRelease(store, isConsultRelease) {
      this.commit('SET_IS_CONSULT_RELEASE', isConsultRelease)
    },
    // eslint-disable-next-line no-unused-vars
    unLogUser(store) {
      this.commit('SET_TOKEN', null)
      this.commit('SET_LOGGED', null)
      return Promise.resolve(true)
    },
  },
}

export default commonStore