<template>
  <div id="side-bar">
    <v-app-bar-nav-icon
      @click.stop="drawer = !drawer"
      class="hidden-md-and-up pt-6 pl-6"
    /> 
    <v-navigation-drawer
      fixed
      left
      :permanent="$vuetify.breakpoint.mdAndUp"
      :class="{ 'mobile-drawer': !$vuetify.breakpoint.mdAndUp }"
      v-model="drawer"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="logo">
            CAPA<span>SCRUM</span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider/>
      
      <v-list nav>
        <v-list-item link to="/">
          <v-list-item-content>
            <v-list-item-title>
              <v-icon>mdi-folder</v-icon>
              Projets
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider/>
      <v-list nav>
        <v-list-item link to="/finishedprojects">
          <v-list-item-content>
            <v-list-item-title>
              <v-icon>mdi-clipboard-check</v-icon>
              Projets terminés
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider/>
      <v-list nav>
        <v-list-item link to="/calculator-module">
          <v-list-item-content>
            <v-list-item-title>
              <v-icon>mdi-chart-box-plus-outline</v-icon>
              Module de calcul 
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider/>
      <v-list nav>
        <v-list-item link to="/members">
          <v-list-item-content>
            <v-list-item-title>
              <v-icon>mdi-account</v-icon>
              Membres
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider/>

      <template v-slot:append>
        <div class="d-flex align-center justify-center py-4">
          <v-btn icon
            outlined
            color="#ffc12b"
            @click="disconnect()">
            <v-icon color="#ffc12b">
              mdi-power
            </v-icon>
          </v-btn>
        </div>
      </template>
    
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'SideBar',
  data() {
    return {
      drawer: false,
    }
  },
  methods: {
    ...mapActions(['unLogUser']),
    disconnect() {
      this.unLogUser()
      .then(() => {
        this.$cookies.remove('access_token')
        this.$router.push('/login')
        this.$awn.success('Vous avez été déconnecté avec succès', {})
      })
      .catch((error) => {
        console.log(error)
      })
        
    },
  }
}
</script>

<style lang="scss">
#side-bar {
  // .v-btn--icon {
  //   color: #f1f1f1;
  //   position: fixed;
  //   z-index: 50;
  //   .v-ripple__container {
  //     left: 12px;
  //     top: 12px;
  //     text-align: center;
  //   }

  //   &::before {
  //     display: none;
  //   }
  // }

  aside {
    width: 300px !important;
  }

  .v-navigation-drawer {
    background-color: #ffffff0e;

    .v-list {
      padding: 0;
    }

    .v-list-item {
      color: #f1f1f1;
      text-align: start;
      padding: 0 4rem;

      .v-list-item__title {
        font-size: 0.9rem;
      }
      .v-icon {
        font-size: 1.2rem;
      }
      .v-icon.mdi-power {
        font-size: 1rem;
        margin-top: 1.5rem;
        color: #ffc12b !important;
      }
      .logo {
        color: #f1f1f1;
        font-size: 1.7rem;
        text-align: center;
        font-weight: lighter;
        margin: 3rem 0 3rem 0;
        span {
          font-weight: bold;
        }
      }
    }
    .v-divider {
      border-color: #f2f2f21e;
    }
    .v-icon {
      color: #f1f1f1;
      // margin-right: 0.5rem;
      // font-size: 1.2rem;
    }
  }
  .mobile-drawer {
    background-color: #241738;
  }
}
</style>