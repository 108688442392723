import Vue from 'vue'

const sprintStore = {
    state: {
        listSprints: [],
        sprint: {
            date_end: '',
            date_start: '',
            holidays: null,
            milestones_git: [],
            offdays: null,
            release_id: null,
            theoric_capacity: null
        },
        openDialogSprints: false,
        isEditingSprint: false,
        listSprintUser: [],
        activeSprintName: null, 
        showGraphDB: false,
        showGraphRates: false,
        showGraphCardsAdvancementFront: false,
        showGraphCardsAdvancementBack: false,
        graphCardsAdvancement: [],
        graphCardsAdvancementBack: [],
        loadingSprint: false,
    },
    getters: {
        getListSprints: (state) => state.listSprints,
        getSprint: (state) => state.sprint,
        getOpenDialogSprints: (state) => state.openDialogSprints,
        getListSprintUser: (state) => state.listSprintUser,
        getSprintEditing: (state) => state.isEditingSprint,
        getActiveSprintName: (state) => state.activeSprintName,
        getShowGraphDB: (state) => state.showGraphDB,
        getShowGraphRates: (state) => state.showGraphRates,
        getShowGraphCardsAdvancementFront: (state) => state.showGraphCardsAdvancementFront,
        getShowGraphCardsAdvancementBack: (state) => state.showGraphCardsAdvancementBack,
        getGraphCardsAdvancement: (state) => state.graphCardsAdvancement,
        getGraphCardsAdvancementBack: (state) => state.graphCardsAdvancementBack,
        getLoadingSprint: (state) => state.loadingSprint,
    },
    mutations: {
        SET_LIST_SPRINTS(state, listSprints) {
            state.listSprints = listSprints
        },
        SET_SPRINT(state, sprint) {
            state.sprint = sprint
        },
        ADD_SPRINT(state, newSprint) {
            state.listSprints.push({ ...newSprint })
        },
        SET_OPEN_DIALOG_SPRINTS(state, openDialogSprints) {
            state.openDialogSprints = openDialogSprints
        },
        SET_LIST_SPRINT_USER(state, listSprintUser) {
            state.listSprintUser = listSprintUser
        },
        SET_SPRINT_EDITING(state, isEditingSprint) {
            state.isEditingSprint = isEditingSprint
        },
        SET_ACTIVE_SPRINT_NAME(state, activeSprintName) {
            state.activeSprintName = activeSprintName
        },
        SET_SHOW_GRAPH_DB(state, showGraph) {
            state.showGraphDB = showGraph
        },
        SET_SHOW_GRAPH_RATES(state, showGraph) {
            state.showGraphRates = showGraph
        },
        SET_SHOW_GRAPH_CARDS_ADVANCEMENT_FRONT(state, showGraphCardsAdvancementFront) {
            state.showGraphCardsAdvancementFront = showGraphCardsAdvancementFront
        },
        SET_SHOW_GRAPH_CARDS_ADVANCEMENT_BACK(state, showGraphCardsAdvancementBack) {
            state.showGraphCardsAdvancementBack = showGraphCardsAdvancementBack
        },
        SET_GRAPH_CARDS_ADVANCEMENT(state, graphCardsAdvancement) {
            state.graphCardsAdvancement = graphCardsAdvancement
        },
        SET_GRAPH_CARDS_BACK_ADVANCEMENT(state, graphCardsAdvancementBack) {
            state.graphCardsAdvancementBack = graphCardsAdvancementBack
        },
        SET_LOADING_SPRINT(state, loadingSprint) {
            state.loadingSprint = loadingSprint
        },
    },
    actions: {
        openDialogSprints(store, openDialogSprints) {
            this.commit('SET_OPEN_DIALOG_SPRINTS', openDialogSprints)
        },
        // eslint-disable-next-line
        loadListSprints(store, params) {
            let urlApi = process.env.VUE_APP_API_ROOT + 'sprints'
            if (params !== null) {
            urlApi += '?'
            for (const item in params) {
            urlApi += item + '=' + params[item] + '&'
            }
            urlApi = urlApi.slice(0, -1)
            }
            return Vue.axios
                .get(urlApi)
                .then((response) => {
                    this.commit('SET_LIST_SPRINTS', response.data)
                    return Promise.resolve(response.data)
                })
                .catch((error) => {
                    return Promise.reject(error)
                })
        },
        loadSprint(store, sprintId) {
            return Vue.axios
                .get(process.env.VUE_APP_API_ROOT + 'sprints/' + sprintId)
                .then((response) => {
                    this.commit('SET_SPRINT', response.data)
                    return Promise.resolve(response.data)
                })
                .catch((error) => {
                    return Promise.reject(error)
                })
        },
        createSprint(store, newSprint) {
            let token = Vue.$cookies.get('access_token')
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            }
            return Vue.axios
                .post(process.env.VUE_APP_API_ROOT + 'sprints', newSprint, config)
                .then((response) => {
                    this.commit('ADD_SPRINT', response.data)
                    this.commit('SET_SPRINT', response.data)
                    return Promise.resolve(response.data)
                })
                .catch((error) => {
                    return Promise.reject(error)
                })
        },
        createSprintUser(store, newSprintUsers) {
            let token = Vue.$cookies.get('access_token')
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            }
            // console.log(newSprintUsers)
            return Vue.axios
                .post(process.env.VUE_APP_API_ROOT + 'sprints/' + newSprintUsers.sprint_id + '/users', newSprintUsers, config)
                .then((response) => {

                    return Promise.resolve(response.data)
                })
                .catch((error) => {
                    return Promise.reject(error)
                })
        },
        addSprint(store, newSprint) {
            this.commit('SET_SPRINT', newSprint)
        },
        calculateCapacity(store, sprintId) {
            let token = Vue.$cookies.get('access_token')
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            }
            return Vue.axios
                .get(process.env.VUE_APP_API_ROOT + 'sprints/' + sprintId + '/calculate_capacity', config)
                .then((response) => {
                    return Promise.resolve(response.data)
                })
                .catch((error) => {
                    return Promise.reject(error)
                })
        },
        updateSprint(store, sprint) {
            let token = Vue.$cookies.get('access_token')
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            }
            return Vue.axios
            .patch(process.env.VUE_APP_API_ROOT + 'sprints/' + sprint.id, sprint, config)
            .then(() => {
                this.commit('SET_SPRINT', sprint)
                return Promise.resolve(sprint)
            })
            .catch((error) => {
                return Promise.reject(error)
            })
        },
        setSprintEditing(store, isEditingSprint) {
            this.commit('SET_SPRINT_EDITING', isEditingSprint)
        },
        setActiveSprintName(store, activeSprintName) {
            this.commit('SET_ACTIVE_SPRINT_NAME', activeSprintName)
        },
        // Graph
        setShowGraphDB(store, showGraph) {
            this.commit('SET_SHOW_GRAPH_DB', showGraph)
        },
        setShowGraphRates(store, showGraph) {
            this.commit('SET_SHOW_GRAPH_RATES', showGraph)
        },
        setShowGraphCardsAdvancementFront(store, showGraph) {
            this.commit('SET_SHOW_GRAPH_CARDS_ADVANCEMENT_FRONT', showGraph)
        },
        setShowGraphCardsAdvancementBack(store, showGraph) {
            this.commit('SET_SHOW_GRAPH_CARDS_ADVANCEMENT_BACK', showGraph)
        },
        fetchGraphCardsAdvancement(store, params) {
            let urlApi = process.env.VUE_APP_API_ROOT + 'sprints/' + params.id + '/cards_report'
            if (params !== null) {
            urlApi += '?'
            for (const item in params) {
            urlApi += item + '=' + params[item] + '&'
            }
            urlApi = urlApi.slice(0, -1)
            }
            return Vue.axios
            .get(urlApi)
            .then((response) => {
                if (params.side === 'Front') {
                    this.commit('SET_GRAPH_CARDS_ADVANCEMENT', response.data)
                } else {
                    this.commit('SET_GRAPH_CARDS_BACK_ADVANCEMENT', response.data)
                }
                return Promise.resolve(response.data)
            })
            .catch((error) => {
                return Promise.reject(error)
            })
        },
        setLoadingSprint(store, loadingSprint) {
            this.commit('SET_LOADING_SPRINT', loadingSprint)
        },
    },

}

export default sprintStore