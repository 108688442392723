<template>
  <v-container fluid>
    <h2>Nos projets en cours</h2>
    <v-row v-if="currentProjects.length > 0" id="current-project">
      <v-col
        v-for="project in currentProjects"
        :key="project.id"
        cols="12"
        lg="4"
        md="4"
        class="d-flex" 
      >
        <v-card v-if="project"
          class="mb-12 d-flex flex-column justify-space-between"
          color="#241738"
          width="100%"
          height="100%"
          dark>

          <v-card-title class="text-h6">
            <a @click="consultProject(project.id)">
              {{ project.label }}
            </a>
          </v-card-title>
          <v-card-subtitle class="font-italic font-weight-light"
          >Release en cours</v-card-subtitle
          >
          <v-card-text>
            <SprintSlider
              v-if="project.active_release.sprints.length > 0"
              :release-project="project.active_release"
            />

          </v-card-text>
          <v-card-actions class="d-flex justify-space-between mx-2 mt-8 flex-wrap">
            <div class="contain-participants d-flex">
              <div
                v-for="participant in project.participants"
                :key="participant.id"
                class="participants"
              >
                <v-btn color="#fff"
                  fab
                  x-small
                  elevation="1"
                  class="mr-1 pa-4">
                  <span class="black--text">
                    {{ participant }}
                  </span>
                </v-btn>
              </div>
            </div>
            <v-btn
              small
              text
              color="#4AD295"
              @click="consultRelease(project.active_release.id)"
            >
              consulter la release
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <AddProject :load-datas="loadDatas"/>

  </v-container>
</template>

<script>
import SprintSlider from './SprintSlider.vue'
import AddProject from '@/components/forms/ProjectsForm.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'CurrentProjects',
  components: {
    SprintSlider,
    AddProject,
  },

  data() {
    return {
      //
    }
  },
  mounted() {
    this.loadDatas()
  },
  computed: {
    ...mapGetters(['getProjects', 'getListSprints', 'getCurrentProjects', 'getDialogProjectCreate']),
    listProjects() {
      return this.getProjects
    },
    sprintsList() {
      return this.getListSprints
    },
    currentProjects() {
      return this.getCurrentProjects
    },
    dialogProjectCreate() {
      return this.getDialogProjectCreate
    },
  },
  methods: {
    ...mapActions([
      'loadListProjects', 
      'loadListParticipants', 
      'setIsConsultRelease',
      'loadListSprints',
      'setShowGraphDB',
      'setShowGraphRates',
      'setShowGraphCardsAdvancementFront',
      'setShowGraphCardsAdvancementBack',
      'setLoadingProject',
      ]),
      loadDatas() {
        this.loadListProjects()
        .then(() => {
          this.setLoadingProject(false)
        })
        .catch((error) => {
          this.setLoadingProject(false)
          console.log(error)
        })
      },

    consultRelease(id) {
      this.setShowGraphDB(false)
      this.setShowGraphRates(false)
      this.setShowGraphCardsAdvancementFront(false)
      this.setShowGraphCardsAdvancementBack(false)
      this.setIsConsultRelease(true)
      this.$router.push('/projects/release/' + id)
    },
    consultProject(id) {
      this.$router.push('/projects/' + id)
    },
  },
}
</script>

<style scoped lang="scss">
h2 {
  color: #f1f1f1;
  font-weight: 500;
  letter-spacing: -0.5px;
  margin-bottom: 1.5rem;
}
#current-project {

  .v-card {
    border-radius: 12px;
      .contain-participants {
        margin-bottom: 0.5rem;
        .participants {
          .v-btn--fab.v-size--x-small {
            height: 20px !important;
            width: 20px !important;
          }
          .pa-4 {
            padding: 14px !important;
          }
        }
      }
  //   }
  }
}

@media screen and (max-width: 600px) {
  #current-project {
    margin: 0  !important;
    .v-card {
      margin: 0 auto;
    }
    
  }
  h2 {
      margin-left: 2rem;
    }
}
</style>