<template>
  <v-container id="login" class="d-flex">
    <v-row class="d-flex justify-center">
      <v-col cols="12"
        xs="10"
        sm="6"
        md="5"
        lg="4"
        xl="4">
        <div class="logo">
          CAPA<span>SCRUM</span>
        </div> 
        <v-card class="elevation-2">
          <v-card-text>
            <h2>Connexion</h2>
            <v-text-field
              id="password"
              name="password"
              label="Mot de passe"
              type="password"
              v-model="password"
              dark
              @keydown.enter="connectUser()"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn 
              text 
              x-small 
              @click="connectUser()" 
              :loading="isLoading"
            >
              Se connecter
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {

  name: 'LoginPage',
  data() {
    return {
      password: '',
      appVersion: this.$version,
      isLoading: false,
    }
  },
  mounted() {
    const userConnected = this.$cookies.get('access_token')

    if (userConnected) {
      this.$router.push('/')
    }
  },
  computed: {
    ...mapGetters(['getLogged', 'getToken']),
    userLogged() {
      return this.getLogged
    },
    token() {
      return this.getToken
    },
  },
  methods: {
    ...mapActions(['setIsLogged', 'loggin', 'setToken']),
    connectUser() {
      this.isLoading = true
      this.loggin({ password: this.password })
        .then((response) => {
          this.isLoading = false
          this.$awn.success('Utilisateur connecté avec succès.', {})

          this.setToken(response.token, 'Bearer')
          this.$cookies.set('access_token', response.token, '1d')
  
          this.setIsLogged(true)
          this.password = ''
          this.$router.push('/')
        })
        .catch((error) => {
          this.isLoading = false
          this.password = ''
          console.log(error)
          this.$awn.alert('une erreur est survenue lors de la connexion', {})
        })
    },
  },
}
</script>

<style lang="scss" scoped>
#login {
  height: 100vh;
  .logo {
    color: #f1f1f1;
    font-size: 1.8rem;
    text-align: center;
    font-weight: lighter;
    margin: 6rem 0 6rem 0;
    span {
      font-weight: bold;
    }
  }
  .v-card {
    background-color: #241738;
    padding: 0.5rem 1.5rem;
    border-radius: 10px;
    h2 {
      color: #ffc12b;
      font-size: 1rem;
      font-weight: 400;
    }
    .v-btn {
      margin-top: 1rem;
      color: #4ad295;
    }
    .v-text-field ::v-deep label {
      font-size: 0.8em;
    }
  }
}
</style>