<template>
  <div>
    <CircularLoader v-if="loading && !showGraph"/>
    <zingchart v-if="!loading && showGraph && !isEmptyDatasGraph" :data="chartData" />
    <p v-if="isEmptyDatasGraph && !loading" class="text-center mt-12 pt-12 font-italic font-weight-light">
      Il n'y a pas de données <v-icon>mdi-emoticon-sad</v-icon>
    </p>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex' 
import CircularLoader from '../CircularLoader.vue'

export default {
  name: 'RatesCards',
  props: {
    sprint: { type: Object, required: true },
  },
  components: {
    CircularLoader,
  },
  data() {
    return {
      loading: false,
      chartData: {
        type: 'bar',
        backgroundColor: '#160a28',
        legend: {
          draggable: true,
          backgroundColor: '#160a28',
          color: 'white',
          header: {
            text: 'Légende',
            backgroundColor: '#f2f2f2',
          },
        },
        scaleX: {
          labels: ['Front', 'Back', 'Total'],
          item: {
            fontSize: 12,
            'font-color': '#f2f2f2',
          },
        },
        scaleY: {
          label: {
            text: 'Nombre de jours',
            'font-color': '#f2f2f2',
          },
          item: {
            fontSize: 12,
            'font-color': '#f2f2f2',
          },
          guide: {
            lineStyle: 'dotted',
            lineColor: '#8B829B',
          },
        },
        plot: {
          animation: {
            effect: 'ANIMATION_FADE_IN',
            method: 'ANIMATION_LINEAR',
            sequence: 'ANIMATION_BY_PLOT',
            speed: 1500,
          },
          marker: {
            'background-color': '#8B829B',
            size: 4,
          },
        },
        series: [
          {
          values: [],
          text: 'Temps passé',
          'legend-item': {
              'font-color': '#f2f2f2',
            },
          'background-color': '#4AD295',
          },
          {
            values: [],
          text: 'Temps Estimé',
          'legend-item': {
            'font-color': '#f2f2f2',
            },
          'background-color': '#FFC12B',  
          },
        ],
      },
    }
  },
  created() {
    this.setShowGraphRates(false)
  },
  computed: {
    ...mapGetters(['getShowGraphRates']),
    showGraph() {
      return this.getShowGraphRates
    },
    isEmptyDatasGraph() {
      return this.chartData.series[0].values.length < 1 && this.chartData.series[1].values.length < 1 ? true : false
    }
  },
  watch: {
    sprint: function() {
      this.loadDatasGraph()
    },
  },
  mounted() {
    this.loadDatasGraph()
  },
  methods: {
    ...mapActions(['loadRatesCards', 'loadSprint', 'setShowGraphRates']),
    loadDatasGraph() {
      this.clearSeriesValues()
      this.loading = true
      this.loadRatesCards(this.sprint.id)
        .then((response) => {
          if (response.length > 0) {
            this.chartData.series[0].values.push(response[1].total_estim_front)
            this.chartData.series[0].values.push(response[0].total_estim_back)
            this.chartData.series[0].values.push(response[2].total_estim_total)
            this.chartData.series[1].values.push(response[1].total_spent_front)
            this.chartData.series[1].values.push(response[0].total_spent_back)
            this.chartData.series[1].values.push(response[2].total_spent_total)
  
            this.chartData.series[0].text = 'Temps estimé'
            this.chartData.series[1].text = 'Temps passé'
  
            this.loading = false
            this.setShowGraphRates(true)
          } else {
            this.loading = false
            this.setShowGraphRates(false)

          }
        })

        .catch((error) => {
          this.loading = false
          console.log(error)
        })
    },
    clearSeriesValues() {
    this.chartData.series = [
          {
          values: [],
          text: 'Temps passé',
          'legend-item': {
              'font-color': '#f2f2f2',
            },
          'background-color': '#4AD295',
          },
          {
          values: [],
          text: 'Temps Estimé',
          'legend-item': {
            'font-color': '#f2f2f2',
            },
          'background-color': '#FFC12B',  
          },
        ]
  }

  },
}
</script>

<style>
</style>