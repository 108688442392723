import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

// ZingChart Graphiques
import 'zingchart/es6' 
import zingchartVue from 'zingchart-vue'
// eslint-disable-next-line
Vue.component('zingchart', zingchartVue)

import moment from 'moment'
import VueMoment from 'vue-moment'
// Load Locales ('en' comes loaded by default)
require('moment/locale/fr')
// Choose Locale
moment.locale('fr')
Vue.use(VueMoment, { moment })

// Awesome-notifications
import VueAWN from 'vue-awesome-notifications'
const options = {
  icons: {
    prefix: '<i class="fa-solid fa-',
    alert: 'triangle-exclamation',
    suffix: '" />',
  },
  labels: {
    tip: 'Conseils',
    info: 'Informations',
    success: 'Succès',
    warning: 'Attention',
    alert: 'Erreur',
    async: 'Chargement...',
    confirm: 'Confirmer',
    confirmOk: 'OK',
    confirmCancel: 'Annuler',
  },
}
Vue.use(VueAWN, options)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
