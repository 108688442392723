import Vue from 'vue'

const projectStore = {
  state: {
    listProjects: [],
    actualProject: {},
    actualProjectWithGlName: {},
    dialogProjectCreate: false,
    gitLabList: [],
    milestonesList: [],
    currentProjects: [],
    finishedProjects: [],
    loadingProject: false,
    showDialogProjectSave: false,
  },
  getters: {
    getProjects: (state) => state.listProjects,
    getActualProject: (state) => state.actualProject,
    getActualProjectWithGlName: (state) => state.actualProjectWithGlName,
    getDialogProjectCreate: (state) => state.dialogProjectCreate,
    getGitLab: (state) => state.gitLabList,
    getMilestones: (state) => state.milestonesList,
    getCurrentProjects: (state) => state.currentProjects,
    getFinishedProjects: (state) => state.finishedProjects,
    getLoadingProject: (state) => state.loadingProject,
    getShowDialogProjectSave: (state) => state.showDialogProjectSave
  },
  mutations: {
    SET_LIST_PROJECTS(state, projects) {
      state.listProjects = projects
      state.listProjects.forEach((project) => {
        if (project.releases.length > 0) {
          project.releases.forEach((release) => {

            // Current Projects
            if (!release.is_archived) {
              project.active_release = release
              // Récup trigram participants
              const participants = []
              if (release.sprints.length > 0) {
                release.sprints.forEach((sprint) => {
                  sprint.users.forEach((user) => {
                    participants.push(user.user.trigram)
                  })
                  // Push participants in project ( unique )
                  project.participants = [...new Set(participants)]
                })
                
              }
              state.currentProjects.push(project)
              state.currentProjects = [...new Map(state.currentProjects.map((m) => [m.id, m])).values()]
              
            } else {
              // Finished projects
              project.archived_release = release
              
              // Récup trigram participants
              const participants = []
              if (release.sprints.length > 0) {
                release.sprints.forEach((sprint) => {
                  sprint.users.forEach((user) => {
                    participants.push(user.user.trigram)
                  })
                  // push participants in project (unique )
                  project.participants = [...new Set(participants)]
                })
              }

              if (!project.active_release) {
                state.finishedProjects.push(project)
                state.finishedProjects = [...new Map(state.finishedProjects.map((m) => [m.id, m])).values()]
              } 
            }
          })
        }
      })
    },
    SET_PROJECT(state, project) {
      state.actualProject = project
    },
    SET_PROJECT_WITH_GL_NAMES(state, newProject) {
      state.actualProjectWithGlName = newProject
    },
    ADD_PROJECT(state, newProject) {
      state.listProjects.push({ ...newProject })
    },
    SET_DIALOG_PROJECT_CREATE(state, dialogProjectCreate) {
      state.dialogProjectCreate = dialogProjectCreate
    },
    SET_GITLAB_LIST(state, gitLabList) {
      state.gitLabList = gitLabList
    },
    REMOVE_PROJECT(state, idProject) {
      let idRemoveProject = null
      state.listProjects.forEach((item, id) => {
        if (item.id == idProject) {
          idRemoveProject = id
        }
      })
      state.listProjects.splice(idRemoveProject, 1)
    },
    SET_MILESTONES_LIST(state, milestonesList) {
      state.milestonesList = milestonesList
    },
    SET_CURRENT_PROJECTS(state, currentProjects) {
      state.currentProjects = currentProjects
    },
    SET_FINISHED_PROJECTS(state, finishedProjects) {
      state.finishedProjects = finishedProjects
    },
    ADD_CURRENT_PROJECT(state, newCurrentProject) {
      state.currentProjects.push({ ...newCurrentProject })
    },
    SET_LOADING_PROJECT(state, loading) {
      state.loadingProject = loading
    },
    SET_SHOW_DIALOG_PROJECT_SAVE(state, showDialog) {
      state.showDialogProjectSave = showDialog
    },
  },
  actions: {
    // eslint-disable-next-line
    loadListProjects(store) {
      return Vue.axios
        .get(process.env.VUE_APP_API_ROOT +'projects')
        .then((response) => {
          this.commit('SET_LIST_PROJECTS', response.data)
          return Promise.resolve(response.data)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    createProject(store, newProject) {

      let token = Vue.$cookies.get('access_token')
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      }
      return Vue.axios
        .post(process.env.VUE_APP_API_ROOT +'projects', newProject, config)
        .then((response) => {
          this.commit('ADD_PROJECT', response.data)
          return Promise.resolve(response.data)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    setNewProjectWithGlName(store, newProject) {
      this.commit('SET_PROJECT_WITH_GL_NAMES', newProject)
    },
    // eslint-disable-next-line
    loadProject(store, id) {
      return Vue.axios
        .get(process.env.VUE_APP_API_ROOT +'projects/'+ id)
        .then((response) => {
          this.commit('SET_PROJECT', response.data)
          return Promise.resolve(response.data)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    deleteProject(store, id) {
      let token = Vue.$cookies.get('access_token')
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      }
      return Vue.axios
        .delete(process.env.VUE_APP_API_ROOT +'projects/'+ id, config)
        .then(() => {
          this.commit('REMOVE_PROJECT', id)
          return Promise.resolve(id)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    setDialogProjectCreate(store, dialogProjectCreate) {
      this.commit('SET_DIALOG_PROJECT_CREATE', dialogProjectCreate)
    },
    // eslint-disable-next-line
    loadGitLabList(store) {
      return Vue.axios
        .get(process.env.VUE_APP_API_ROOT +'gitlabs/projects/list')
        .then((response) => {
          this.commit('SET_GITLAB_LIST', response.data)
          return Promise.resolve(response.data)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    loadMilestonesList(store, projectId) {
      return Vue.axios
      .get(process.env.VUE_APP_API_ROOT +'gitlabs/project/' + projectId + '/milestones')
      .then((response) => {
        this.commit('SET_MILESTONES_LIST', response.data)
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
    },
    setCurrentProjects(store, currentProjects) {
      this.commit('SET_CURRENT_PROJECTS', currentProjects)
    },
    addCurrentProject(store, addCurrentProject) {
      this.commit('ADD_CURRENT_PROJECT', addCurrentProject)
    },
    setLoadingProject(store, loading) {
      this.commit('SET_LOADING_PROJECT', loading)
    },
    setShowDialogProjectSave(store, showDialog) {
      this.commit('SET_SHOW_DIALOG_PROJECT_SAVE', showDialog)
    },
  },
}

export default projectStore