<template>
  <div id="contain-add-project">

    <!-- Dialog Project -->
    <v-dialog v-model="dialogProjectCreate" persistent max-width="600">
      <div class="dialog-project">
        <v-row class="d-flex justify-center align-content-space-between">
          <v-col cols="12" sm="11" md="11">
            <div>
              <h2>Créer un nouveau projet</h2>
              <v-text-field
                dark
                dense
                label="Nom du projet *"
                v-model="label"
                :rules="[rules.required]"
              />
            </div>
            <div
              class="
                d-flex
                justify-space-between
                align-center
                footer-dialog-project
                mt-12
              "
            >
              <span>* Champs obligations</span>
              <div>
                <v-btn class="cancel-btn"
                  plain
                  @click="cancel()"
                >annuler</v-btn
                >
                <v-btn class="create-btn"
                  plain
                  @click="createNewProject()"
                  :loading="isLoading"
                >créer le projet</v-btn
                >
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-dialog>

    <!-- dialog création project next-->
    <!-- <v-dialog v-model="dialogProjectSave" persistent max-width="650">
      <div class="dialog-project">
        <v-row class="d-flex justify-center align-content-space-between">
          <v-col cols="12" sm="11" md="11">
            <div class="dialog-create-next">
              <h3>{{ label }}</h3> -->
    <!-- ajouter une release -->
    <!-- <v-btn elevation="1"
                rounded
                @click="openModalRelease()"
              ><v-icon>mdi-plus-circle</v-icon>ajouter une nouvelle
                release</v-btn
              >
              <ReleasesForm v-model="openDialogRelease" />
            </div>
            <div
              class="
                d-flex
                justify-end
                align-center
                footer-dialog-project
                mt-12
              "
            >
              <div class="d-flex">
                <v-btn color="#FF7B88"
                  plain
                  @click="confirmDelete()"
                >supprimer le projet</v-btn
                >
                <v-btn color="#4ad295"
                  plain
                  @click="saveNewProject()"
                >sauvegarder</v-btn
                >
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-dialog> -->
    <!-- dialog confirm suppression -->
    <v-dialog v-model="dialogDeleteConfirmation" max-width="650">
      <div class="dialog-project">
        <v-row class="d-flex justify-center align-center">
          <v-col
            cols="12"
            sm="11"
            md="11"
            class="d-flex flex-column justify-center align-center"
          >
            <p>VOULEZ-VOUS SUPPRIMER CE PROJET ?</p>

            <div
              class="d-flex justify-center align-center footer-dialog-project"
            >
              <div class="d-flex">
                <v-btn color="#FF7B88"
                  plain
                  @click="cancelDelete()" 
                >annuler</v-btn
                >
                <v-btn color="#4ad295"
                  plain
                  @click="deleteNewProject()"
                  :loading="isLoading"
                >confirmer</v-btn
                >
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-dialog>
    <AddReleaseProject :label="label"/>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AddReleaseProject from './AddReleaseProject.vue'

export default {
  name: 'AddProject',
  components: {
    AddReleaseProject,
  },
  data() {
    return {
      dialogProjectSave: false,
      dialogDeleteConfirmation: false,
      label: '',
      rules: {
        required: (value) => !!value || 'Champs requis',
      },
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters(['getGitLab', 
    'getProjects', 
    'getActualProject', 
    'getOpenDialogRelease', 
    'getReleaseEditing', 
    'getDialogProjectCreate',
    'getShowAddProject',
    ]),
    GitLab() {
      return this.getGitLab
    },
    listProjects() {
      return this.getProjects
    },
    project() {
      return this.getActualProject
    },
    openDialogRelease() {
      return this.getOpenDialogRelease
    },
    editingRelease() {
      return this.getReleaseEditing
    },
    dialogProjectCreate() {
      return this.getDialogProjectCreate
    },
  },
  methods: {
    ...mapActions([
      'createProject',
      'loadGitLabList',
      'deleteProject',
      'loadListProjects',
      'loadProject',
      'setOpenDialogRelease',
      'setReleaseEditing',
      'setDialogProjectCreate',
      'setShowDialogProjectSave',
    ]),
    loadDatas() {
      this.loadListProjects()
      .then(() => {})
      .catch((error) => {
        console.log(error)
      })
    },
    createNewProject() {
      if (this.label) {
        // this.dialogProjectSave = true
        this.setShowDialogProjectSave(true)
        this.setDialogProjectCreate(false)
        this.isLoading = true

        this.createProject({ label: this.label })
          .then((response) => {
            this.isLoading = false
            this.$awn.success('Projet créé avec succès.', {})
            this.loadDatas()
            this.label = ''

            this.loadProject(response.id)
            .then(() => {})
            .catch((error) => {
              console.log(error)
            })
          })
          .catch((error) => {
            this.isLoading = false
            this.$awn.alert('Une erreur est survenue lors de la création.', {})
            console.log(error)
          })
      }
    },
    confirmDelete() {
      this.dialogDeleteConfirmation = true
      this.dialogProjectSave = false
    },
    cancelDelete() {
      this.dialogProjectSave = true
    },
    deleteNewProject() {
      this.isLoading = true
      this.deleteProject(this.project.id)
        .then(() => {
          this.isLoading = false
          this.$awn.success('Projet supprimé avec succès.', {})
          this.dialogDeleteConfirmation = false
          this.label = ''
        })
        .catch((error) => {
          this.isLoading = false
          this.$awn.alert(
            'Une erreur est survenue lors de la suppression.',
            {}
          )
          console.log(error)
        })
    },
    saveNewProject() {
      this.dialogDeleteConfirmation = false
      this.dialogProjectSave = false
      this.label = ''
    },
    cancel() {
      this.setDialogProjectCreate(false)
      this.dialogProjectSave = false
      this.label = ''
    },
    openModalRelease() {
      this.setOpenDialogRelease(true)
      this.dialogProjectSave = false
    },
  },
}
</script>

<style lang="scss" scoped>
#contain-add-project,
.dialog-create-next {
  .v-btn {
    background: rgb(56, 248, 203);
    background: linear-gradient(
      180deg,
      rgba(56, 248, 203, 1) 0%,
      rgba(74, 210, 149, 1) 100%
    );
    color: #150041;
    font-size: 0.6rem;
    font-weight: 600;
    letter-spacing: -0.5px;
    .v-icon {
      margin-right: 0.5rem;
      color: #150041;
      font-size: 1.2rem;
    }
  }
}
.v-dialog {
  .dialog-project {
    display: flex;
    flex-direction: column;
    height: 50%;
    background-color: #241738;
    padding: 1rem;
    h2 {
      margin-bottom: 2rem;
      color: #ffc12b;
      font-weight: 500;
      letter-spacing: -0.5px;
      font-size: 1.4rem;
    }
    h3 {
      margin-bottom: 2rem;
      color: #ffc12b;
      font-weight: 500;
      letter-spacing: -0.5px;
      font-size: 2rem;
    }

    p {
      color: #ff7b88;
      font-size: 1.5rem;
      font-weight: 600;
      margin-top: 1rem;
    }

    .footer-dialog-project {
      // margin-top: 8rem;
      span {
        color: #9e9e9e;
        font-size: 0.7rem;
      }
      .v-btn {
        letter-spacing: -0.5px;
      }
      .cancel-btn {
        color: #ffde8d;
        letter-spacing: -0.5px;
      }
      .create-btn {
        color: #4ad295;
        letter-spacing: -0.5px;
      }
      .archived-btn {
        color: #8b829b;
        letter-spacing: -0.5px;
        padding-left: 0;
      }
    }
  }
}
.theme--dark.v-sheet {
  background-color: #241738 !important;
}
</style>