<template>
  <v-dialog v-model="showDialogProjectSave" persistent max-width="650">
    <div class="dialog-project">
      <v-row class="d-flex justify-center align-content-space-between">
        <v-col cols="12" sm="11" md="11">
          <div class="dialog-create-next">
            <h3>{{ label }}</h3>
            <!-- ajouter une release -->
            <v-btn elevation="1"
              rounded
              @click="openModalRelease()"
            ><v-icon>mdi-plus-circle</v-icon>ajouter une nouvelle
              release</v-btn
            >
          </div>
          <div
            class="
                d-flex
                justify-end
                align-center
                footer-dialog-project
                mt-12
              "
          >
            <div class="d-flex">
              <v-btn color="#FF7B88"
                plain
                @click="confirmDelete()"
              >supprimer le projet</v-btn
              >
              <v-btn color="#4ad295"
                plain
                @click="saveNewProject()"
              >sauvegarder</v-btn
              >
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <ReleasesForm v-model="openDialogRelease" />
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import ReleasesForm from '@/components/forms/ReleasesForm.vue'
export default {
  name: 'AddReleaseProject',
  props: {
    label: {
      type: String,
      required: true
    }
  },
  components: {
    ReleasesForm,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters([
      'getShowDialogProjectSave',
      'getOpenDialogRelease',
    ]),
    showDialogProjectSave() {
      return this.getShowDialogProjectSave
    },
    openDialogRelease() {
      return this.getOpenDialogRelease
    },
  },
  mounted() {},
  methods: {
    ...mapActions([
      'setShowDialogProjectSave',
      'setOpenDialogRelease',
      'setReleaseEditing'
    ]),
    openModalRelease() {
      this.setReleaseEditing(false)
      this.setOpenDialogRelease(true)
      this.setShowDialogProjectSave(false)
    },
  }
}
</script>

<style lang="scss" scoped>
.dialog-create-next {
  .v-btn {
    background: rgb(56, 248, 203);
    background: linear-gradient(
      180deg,
      rgba(56, 248, 203, 1) 0%,
      rgba(74, 210, 149, 1) 100%
    );
    color: #150041;
    font-size: 0.6rem;
    font-weight: 600;
    letter-spacing: -0.5px;
    .v-icon {
      margin-right: 0.5rem;
      color: #150041;
      font-size: 1.2rem;
    }
  }
}
.v-dialog {
  .dialog-project {
    display: flex;
    flex-direction: column;
    height: 50%;
    background-color: #241738;
    padding: 1rem;
    h2 {
      margin-bottom: 2rem;
      color: #ffc12b;
      font-weight: 500;
      letter-spacing: -0.5px;
      font-size: 1.4rem;
    }
    h3 {
      margin-bottom: 2rem;
      color: #ffc12b;
      font-weight: 500;
      letter-spacing: -0.5px;
      font-size: 2rem;
    }

    p {
      color: #ff7b88;
      font-size: 1.5rem;
      font-weight: 600;
      margin-top: 1rem;
    }

    .footer-dialog-project {
      // margin-top: 8rem;
      span {
        color: #9e9e9e;
        font-size: 0.7rem;
      }
      .v-btn {
        letter-spacing: -0.5px;
      }
      .cancel-btn {
        color: #ffde8d;
        letter-spacing: -0.5px;
      }
      .create-btn {
        color: #4ad295;
        letter-spacing: -0.5px;
      }
      .archived-btn {
        color: #8b829b;
        letter-spacing: -0.5px;
        padding-left: 0;
      }
    }
  }
}
.theme--dark.v-sheet {
  background-color: #241738 !important;
}
</style>