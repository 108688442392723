<template>
  <v-container >
    <v-row class="text-center">
      <v-col>
        <div class="opacBackground">
          <div id="frame_circle">
            <div class="circle">
              <div class="circles-eyes">
                <div class="blush-1"/>
                <div class="blush-2"/>
                <div class="mouth happy"/>
              </div>
            </div>
            <!-- <div class="innerCircle"/> -->
          </div>
          <div id="frame"> 
            <div class="bar"/>
            <div class="bar"/>
            <div class="bar"/>
            <div class="bar"/>
            <div class="bar"/>
            <div class="bar"/>
            <div class="bar"/>
            <div class="bar"/>
            <div class="bar"/>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  // eslint-disable-next-line
  name: "Loader",
}
</script>

<style scoped lang="scss">
#frame {
    position:absolute;
    top: 0;
    left:0;
    right:0;
    bottom:0;
    width:50px;
    height:50px;
    margin:auto auto;
    animation: spin 8s linear infinite;
}
@keyframes spin {
    from { -webkit-transform: rotate(0deg);}
    to { -webkit-transform: rotate(360deg);}
}

@keyframes gelatine {
  from, to { transform: scale(1, 1); }
  25% { transform: scale(0.95, 1.1); }
  50% { transform: scale(1.1, 0.95); }
  75% { transform: scale(0.95, 1.05); }
}
@keyframes bounce {
	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-10px);}
	60% {transform: translateY(-20px);}
}
// .innerCircle {
//     position:absolute;
//     top:0;
//     left:0px;
//     right:0;
//     bottom:0;
//     margin:auto;
//     width:80px;
//     height:80px;
//     border-radius:100%;
//     z-index:2;
// }
.circle {
    position:absolute;
    background: #FFC12B;
    top:0;
    left:0;
    right:0;
    bottom:0;
    margin:auto;
    width:50px;
    height:50px;
    border-radius:100%;
    animation: gelatine 1s infinite;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}

.circles-eyes {
    background: black;
    border-radius: 50%;
    width: 6px;
    height: 6px;
    margin-left: 28%;
    margin-top: 40%;
    z-index: 50;
    &:before {
      content: '';
      position: absolute;
      right: 20%;
      width: 6px;
      height: 6px;
      background: black;
      border-radius: 50%;
    }

    .blush-1, .blush-2 {
      position: absolute;
      opacity: .7;
      width: 10px;
      height: 8px;
      background: rgb(255, 129, 150);
      border-radius: 50%;
      top: 55%;
    }
    .blush-1 {
      left: 20%;
    }
    .blush-2 {
      right: 15%;
    }
    .mouth {
      position:absolute;
      top: 40%;
      left: 45%;
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
    .happy {
      border: 3px solid;
      border-color: transparent black black transparent;
      transform: rotate(45deg);
    }

}

#frame .bar {
  position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    margin:auto;
    width:2px;
    height:120px;
    z-index:10;
}

#frame .bar:before {
  content:'';
    position:absolute;
    background: #FFC12B;
    width:5px;
    height:25px;
    border-radius: 50px;
    animation: bounce 1s ease infinite;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 100px 4px;
}

.bar:nth-child(1) {transform:rotate(40deg);}
.bar:nth-child(1) {transform:rotate(40deg);}
.bar:nth-child(2) {transform:rotate(80deg);}
.bar:nth-child(3) {transform:rotate(120deg);}
.bar:nth-child(4) {transform:rotate(160deg);}
.bar:nth-child(5) {transform:rotate(200deg);}
.bar:nth-child(6) {transform:rotate(240deg);}
.bar:nth-child(7) {transform:rotate(280deg);}
.bar:nth-child(8) {transform:rotate(320deg);}
.bar:nth-child(9) {transform:rotate(360deg);}

</style>