<template>
  <div v-if="sprintsList.length > 0" class="mx-4">
    <!-- <div id="slidecontainer">
      <div v-for="(sprint, index) in sprintsList" :key="sprint.id">
        <div class="sprint mt-8 mx-auto" :ref="sprint.id"/>
        <div class="pt-2">
          <span @click="displaySprint(sprint.id, index + 1)">
            SPRINT {{ index + 1 }}
          </span>
        </div>
      </div>
    </div> -->
    <div id="slidecontainer">
      <div v-for="(sprint, index) in sprintsList" :key="sprint.id">
        <v-avatar
          :size="sprint.size"
          :color="sprint.color"
          @click="displaySprint(sprint.id, index + 1)"
        >
          <span class="font-weight-bold">
            S {{ index + 1 }}
          </span>
        </v-avatar>
      </div>
    </div>
  </div>
  <div v-else>
    <h3 class="text-center font-italic font-weight-regular">
      Aucun sprints
    </h3>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'SprintSlider',
  props: {
    releaseProject: {
      type: Object,
      required: false
    }
  },
  data() {
    return {}
  },
  mounted() {
    // this.colorSprints()
  },
  updated() {
    // this.colorSprints()
  },
  computed: {
    ...mapGetters([
      'getSprint',
      'getActiveSprintName',
      'getIsSprintSliderInCards',
      'getIsConsultRelease',
      'getRelease',
      ]),
    currentSprint() {
      return this.getSprint
    },
    currentSprintId() {
      return this.getSprint.id
    },
    activeSprintName() {
      return this.getActiveSprintName
    },
    isSprintSliderInCards() {
      return this.getIsSprintSliderInCards
    },
    isConsultRelease() {
      return this.getIsConsultRelease
    },
    release() {
      return this.getRelease
    },
    sprints() {
      return this.release.sprints
    },
    sprintsList() {
      if (this.release !== undefined || this.releaseProject !== undefined) {
        if (this.isSprintSliderInCards) {
          if (this.releaseProject && this.releaseProject.sprints.length > 0) {
            this.releaseProject.sprints.forEach((sprint) => {
              if (sprint.is_active) {
                sprint.color = '#ffc12b'
                sprint.size = '32'
              } else {
                sprint.color = '#4AD295'
                sprint.size = '24'
              }
            })
          }
          return this.releaseProject ? this.releaseProject.sprints : []
        } else {
          if ( this.release && this.release.sprints.length > 0) {
            this.release.sprints.forEach((sprint) => {
              if (sprint.is_active) {
                sprint.color = '#ffc12b'
                sprint.size = '32'
              } else {
                sprint.color = '#4AD295'
                sprint.size = '24'
              }
            })
          }
          return this.release ? this.release.sprints : []
        }
      } else {
        return []
      }
    },
  },
  methods: {
    ...mapActions([
      'loadSprint',
      'setActiveSprintName',
      'setShowGraphDB',
      'setShowGraphRates',
      'setIsSprintSliderInCards',
      'setIsConsultRelease',
      'setShowGraphCardsAdvancementFront',
      'setShowGraphCardsAdvancementBack',
      'setLoadingSprint',
    ]),
    displaySprint(id, index) {
      this.setLoadingSprint(true)
      this.setActiveSprintName(index)
      this.setShowGraphDB(false)
      this.setShowGraphRates(false)
      this.setShowGraphCardsAdvancementFront(false)
      this.setShowGraphCardsAdvancementBack(false)

    if (this.isSprintSliderInCards) {
        this.setIsConsultRelease(false)
        if (this.isSprintSliderInCards) {
          this.$router.push('/projects/release/' + this.releaseProject.id)
        } else {
          this.$router.push('/projects/release/' + this.release.id)
        }
    } 
        this.loadSprint(id)
          .then(() => {
            this.setLoadingSprint(false)
          })
        .catch((error) => {
          this.setLoadingSprint(false)
          console.log(error)
        })
      },
    // colorSprints() {
    //   if (this.sprintsList.length > 0) {
    //     let isFinished = true
    //     this.sprintsList.forEach((element) => {
    //       if (isFinished) {
    //           // let [sprintFinished] = this.$refs[element.id]
    //           // sprintFinished.classList.add('sprintFinish')
    //           element.color = '#4AD295'
    //       }
    //       if (element.is_active === true) {
    //         // let [sprintActived] = this.$refs[element.id]
    //         //   sprintActived.classList.add('active')
    //           isFinished = false
    //         element.color = '#ffc12b'
    //       }
    //     })
    //   }
    // }
  },
}
</script>

<style lang="scss" scoped>
#slidecontainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 3px;
  background-color: rgb(216, 216, 216);
}
.active {
  width: 30px !important;
  height: 30px !important;
  background: linear-gradient(
    to bottom,
    rgba(255, 222, 141, 1) 0%,
    rgba(255, 193, 43, 1) 100%
  ) !important;
  border-radius: 50% !important;
  margin-right: 0.5rem !important;
}
.sprintFinish {
  width: 20px;
  height: 20px;
  background: linear-gradient(
    to bottom,
    rgba(56, 246, 203, 1) 0%,
    rgba(74, 210, 149, 1) 100%
  );
  border-radius: 50%;
}
.sprint {
  width: 20px;
  height: 20px;
  background-color: #8b829b;
  border-radius: 50%;
}

.listSprint {
  margin-top: 5rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

span {
  text-decoration: none;
  font-size: 60%;
  color: black !important;
  cursor: pointer;
}
</style>