<template>
  <v-container id="contain-members" fluid>
    <v-row>
      <v-col cols="12"
        md="12"
        lg="12"
        class="white--text">
        <div class="d-flex justify-space-between">
          <div class="d-flex align-start flex-column">
            <a href="/">Retour</a>
            <h2 class="mb-2">
              Calcul des capacités théoriques
            </h2>
          </div>
        </div>
      </v-col>
    </v-row>
    <!--  -->
    <v-row>
      <v-col cols="12" md="5" lg="5">
        <v-row>
          <v-col cols="12"
            md="12"
            lg="12">
            <v-card dark class="pa-6">
              <h2 class="white--text font-italic mb-6 d-flex justify-space-between">
                Informations génériques
                <v-btn icon @click="showInformations = !showInformations">
                  <v-icon :color="showInformations ? 'secondary' : 'primary'">
                    {{ showInformations ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                  </v-icon>
                </v-btn>
              </h2>
              <v-expand-transition>
                <div v-show="showInformations">
                  <!-- sprint duration weeks -->
                  <v-row>
                    <v-col cols="12"
                      md="6"
                      lg="6"
                      class="mt-2">
                      <v-text-field v-model="informations.sprint_duration_weeks"
                        type="number"
                        dark
                        dense
                        label="Durée du sprint : (semaines)">
                        <!--  -->
                      </v-text-field>
                    </v-col>
                    <!-- sprint duration days -->
                    <v-col cols="12"
                      md="6"
                      lg="6"
                      class="mt-2">
                      <v-text-field v-model="informations.sprint_duration_days"
                        type="number"
                        dark
                        disabled
                        dense
                        label="Durée du sprint : (jours ouvrés)">
                        <!--  -->
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <!-- Date start -->
                  <v-row>
                    <v-col cols="12" md="6" lg="6">
                      <v-text-field v-model="informations.date_start"
                        label="Début du sprint :"
                        dark
                        dense
                        type="date"
                        max="2300-01-01"/>
                    </v-col>
                    <!-- Date fin -->
                    <v-col cols="12" md="6" lg="6">
                      <v-text-field v-model="informations.date_end"
                        label="Fin du sprint :"
                        dark
                        disabled
                        dense
                        type="date"
                        max="2300-01-01"/>
                    </v-col>
                  </v-row>
                  <!-- team unavailability -->
                  <v-row>
                    <v-col cols="12" md="12" lg="12">
                      <v-text-field v-model="informations.team_unavailability"
                        type="number"
                        dark
                        dense
                        label="Indisponibilité de l'équipe complète : (jours)">
                        <!--  -->
                      </v-text-field>
                    </v-col>
                  </v-row>
                </div>
              </v-expand-transition>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12" lg="12">
            <v-card dark class="pa-6">
              <h2 class="white--text font-italic mb-6 d-flex justify-space-between">
                Temps SCRUM / sprint
                <v-btn icon @click="showScrumTimes = !showScrumTimes">
                  <v-icon :color="showScrumTimes ? 'secondary' : 'primary'">
                    {{ showScrumTimes ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                  </v-icon>
                </v-btn>
              </h2>
              <v-expand-transition>
                <div v-show="showScrumTimes">
                  <v-form ref="form_scrum_valid" v-model="formScrumValid">
                    <v-row>
                      <!-- duration delivery -->
                      <v-col cols="12"
                        md="6"
                        lg="6"
                        class="mt-2">
                        <v-text-field v-model="scrum.duration_delivery"
                          type="number"
                          :rules="[rules.required]"
                          dark
                          dense
                          label="Livraison : (jours)">
                          <!--  -->
                        </v-text-field>
                      </v-col>
                      <!-- duratin plannif -->
                      <v-col cols="12"
                        md="6"
                        lg="6"
                        class="mt-2">
                        <v-text-field v-model="scrum.duration_plannif"
                          type="number"
                          :rules="[rules.required]"
                          dark
                          dense
                          label="Plannification : (jours)">
                          <!--  -->
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <!-- Duration demo -->
                      <v-col cols="12" md="6" lg="6">
                        <v-text-field v-model="scrum.duration_demo"
                          type="number"
                          dark
                          :rules="[rules.required]"
                          dense
                          label="Démo : (jours)">
                          <!--  -->
                        </v-text-field>
                      </v-col>
                      <!-- Duration retro -->
                      <v-col cols="12" md="6" lg="6">
                        <v-text-field v-model="scrum.duration_retro"
                          type="number"
                          dark
                          dense
                          label="Rétrospective : (jours)">
                          <!--  -->
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <!-- duration sum hours -->
                      <v-col cols="12" md="6" lg="6">
                        <v-text-field v-model="scrum.duration_sum_hours"
                          type="number"
                          dark
                          dense
                          label="SUM : (heures)">
                          <!--  -->
                        </v-text-field>
                      </v-col>
                      <!-- duration sum days -->
                      <v-col cols="12" md="6" lg="6">
                        <v-text-field v-model="sum_days"
                          type="number"
                          dark
                          disabled
                          dense
                          label="SUM : (jours)">
                          <!--  -->
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12"
                        md="12"
                        lg="12"
                        class="d-flex justify-center">
                        <v-btn color="secondary" @click="calculateTeamTimes()">
                          Calculer temps portés
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </div>
              </v-expand-transition>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12" lg="12">
            <v-card dark class="pa-6">
              <h2 class="white--text font-italic mb-6 d-flex justify-space-between">
                Temps équipe
                <v-btn icon @click="showUserTime = !showUserTime">
                  <v-icon :color="showUserTime ? 'secondary' : 'primary'">
                    {{ showUserTime ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                  </v-icon>
                </v-btn>
              </h2>
              <v-expand-transition>
                <div v-show="showUserTime">
                  <v-form ref="form_members_valid" v-model="formMembersValid">
                    <v-row>
                      <v-col cols="12" md="12" lg="12">
                        <v-select :items="listMembers"
                          v-model="userTime.user"
                          item-text="fullname"
                          item-value="trigram"
                          :rules="[rules.required]"
                          dark
                          dense
                          label="Concerné par le sprint">
                          <!--  -->
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6" lg="6">
                        <v-text-field v-model="userTime.specific_availability"
                          type="number"
                          dark
                          dense
                          label="Indisponibilité spécifique : (jour/sprint)">
                          <!--  -->
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" lg="6">
                        <v-text-field v-model="userTime.risk_rate"
                          type="number"
                          dark
                          dense
                          label="Taux de risque : (%)">
                          <!--  -->
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="12" lg="12">
                        <v-text-field v-model="userTime.prod_time"
                          type="number"
                          dark
                          max="5"
                          dense
                          label="Temps alloué à la production : (jour/semaine)">
                          <!--  -->
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12"
                        md="12"
                        lg="12"
                        class="d-flex justify-center">
                        <v-btn color="secondary" @click="addUserTime()">
                          Ajouter
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </div>
              </v-expand-transition>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <!-- Resum col -->
      <v-col cols="12" md="7" lg="7">
        <v-card class="pa-6 resum-card" >
          <v-card-title class="white--text d-flex justify-center pt-0">
            Résumé des informations
          </v-card-title>
          <!-- Informations -->
          <v-card-text>
            <div class="white--text">
              Début du sprint : 
              <span v-if="dateSprintStart" class="primary--text">{{ (dateSprintStart) }}</span>
              <span v-else class="grey--text font-italic">Non renseigné</span>
            </div>
            <div class="white--text">
              Fin du sprint :
              <span  v-if="dateEnd" class="primary--text">{{ (dateEnd) }}</span>
              <span v-else class="grey--text font-italic">Non renseigné</span> 
            </div>
            <div class="white--text">
              Durée du sprint : 
              <span v-if="informations.sprint_duration_days && informations.sprint_duration_weeks" class="primary--text">{{ informations.sprint_duration_days + ' jours' + ' ' + '( ' + informations.sprint_duration_weeks + ' semaines )'}}</span>
              <span v-else class="grey--text font-italic">Non renseigné</span> 
            </div>
            <div class="white--text">
              Indisponibilité de l'équipe complète :
              <span v-if="informations.team_unavailability" class="primary--text">
                {{ informations.team_unavailability + ' jours' }}
              </span>
              <span v-else class="grey--text font-italic">Non renseigné</span> 
            </div>
            <div class="white--text">
              Nombre de jours effectifs réels :
              <span v-if="numberDaysReals" class="primary--text">
                {{ numberDaysReals + ' jours' }}
              </span>
              <span v-else class="grey--text font-italic">Non renseigné</span>
            </div>
          </v-card-text>
          <v-divider dark/>
          <v-card-text>
            <div class="white--text">
              Temps SCRUM / sprint :
            </div>
            <v-row class="white--text mt-2">
              <v-col cols="12" md="4" lg="4">
                Livraison :
                <span v-if="scrum.duration_delivery" class="primary--text">{{ scrum.duration_delivery + ' jours' }}</span>
                <span v-else class="grey--text font-italic">Non renseigné</span> 
              </v-col>
              <v-col cols="12"
                md="4"
                lg="4">
                Plannification : 
                <span v-if="scrum.duration_plannif" class="primary--text">{{ scrum.duration_plannif  + ' jours'}}</span>
                <span v-else class="grey--text font-italic">Non renseigné</span> 
              </v-col>
              <v-col cols="12"
                md="4"
                lg="4"
                class="d-flex justify-center">
                Démo :
                <span v-if="scrum.duration_demo" class="primary--text">{{ '&nbsp;' + scrum.duration_demo  + ' jours'}}</span>
                <span v-else class="grey--text font-italic">Non renseigné</span> 
              </v-col>
            </v-row>
            <v-row class="white--text mt-0">
              <v-col cols="12"
                md="6"
                lg="6"
                class="pt-0">
                Rétrospective :
                <span v-if="scrum.duration_retro" class="primary--text">{{ scrum.duration_retro  + ' jours'}}</span>
                <span v-else class="grey--text font-italic">Non renseigné</span> 
              </v-col>
              <v-col cols="12"
                md="6"
                lg="6"
                class="pt-0">
                SUM :
                <span v-if="sum_days && scrum.duration_sum_hours" class="primary--text">
                  {{ scrum.duration_sum_hours + ' heures' + ' ( ' + sum_days + ' jours )' }}
                </span>
                <span v-else class="grey--text font-italic">Non renseigné</span> 
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col cols="12" md="12" lg="12">
                <span class="white--text">
                  Temps portés par l'équipe hors SM : 
                  <span :class="scrum.team_time ? 'primary--text' : 'grey--text'"> 
                    {{ scrum.team_time + ' jour(s) par sprint par personne' }}
                  </span> 
                </span>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider dark/>
          <v-card-text class="table">
            <div class="white--text mb-4">
              Temps de chaque personne :
            </div>
            <v-data-table
              dense
              dark
              hide-default-footer
              :items="userTimeList"
              :headers="headers"
              class="elevation-1"
            >
              <template #[`item.total_without_scrum`]="{ item }">
                {{ item.total_without_scrum.toFixed(2) }}
              </template>
              <template #[`item.user`]="{ item }">
                <v-chip small color="primary" class="black--text">
                  {{ item.user }}
                </v-chip>
              </template>
              <template #[`item.actions`]="{ item }">
                <v-btn icon
                  color="red"
                  @click="removeUser(item)">
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
          <v-divider dark/>
          <v-card-text class="white--text">
            <h3>
              Capacité totale de l'équipe : 
              <span :class="totalTeamCapacity ? 'primary--text' : 'grey--text'">
                {{ totalTeamCapacity.toFixed(2) + ' jours' }}
              </span>
            </h3>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'CalculatorModule',
  data() {
    return {
      showInformations: true,
      showScrumTimes: false,
      showUserTime: false,
      informations: {
        sprint_duration_weeks: 0,
        sprint_duration_days: 0,
        date_start: '',
        date_end: '',
        team_unavailability: 0,
      },
      dateSprintStart: '',
      dateSprintEnd: '',
      scrum: {
        duration_delivery: parseFloat(process.env.VUE_APP_DURATION_DELIVERY),
        duration_plannif: parseFloat(process.env.VUE_APP_DURATION_PLANNIF),
        duration_demo: parseFloat(process.env.VUE_APP_DURATION_DEMO),
        duration_retro: parseFloat(process.env.VUE_APP_DURATION_RETRO),
        duration_sum_hours: parseFloat(process.env.VUE_APP_DURATION_DAILY),
        team_time: 0,
      },
      userTime: {
        user: '',
        specific_availability: 0,
        risk_rate: 0,
        prod_time: 0,
        total: 0,
        total_without_scrum: 0,
      },
      totalTeamCapacity: 0,
      formScrumValid: false,
      formMembersValid: false,
      rules: {
        required: value => !!value || 'Champs requis',
      },
      userTimeList: [],
      headers: [
        { text: '', value: 'user', sortable: null },
        { text: 'Indispo', value: 'specific_availability', sortable: null },
        { text: '% de risque', value: 'risk_rate', sortable: null },
        { text: 'Tps production', value: 'prod_time', sortable: null },
        { text: 'Total', value: 'total', sortable: null },
        { text: 'Total (- SCRUM)', value: 'total_without_scrum', sortable: null },
        { text: '', value: 'actions', sortable: null },
      ]
    }
  },
  computed: {
    ...mapGetters([
      'getListMembers',
    ]),
    listMembers() {
      return this.getListMembers
    },
    sprintDuration() {
      return this.informations.sprint_duration_weeks
    },
    sprintEndDate() {
      if (this.informations.date_start) {
        let numberWeeks = this.sprintDuration
        let dateEnd = new Date(this.informations.date_start)
        dateEnd.setDate(dateEnd.getDate() + parseInt(numberWeeks) * 7)
        return dateEnd.toISOString().substring(0, 10)
      } else {
        return ''
      }
    },
    sprintStartDate() {
      return this.informations.date_start
    },
    numberDaysReals() {
      if (this.informations.sprint_duration_days) {
        return this.informations.sprint_duration_days - this.informations.team_unavailability
      } else {
        return 0
      }
    },
    sum_days() {
      return parseFloat(this.scrum.duration_sum_hours / 7).toFixed(3)
    },
    dateStart() {
      return this.informations.date_start ? new Date(this.informations.date_start).toLocaleDateString('fr-FR') : ''
    },
    dateEnd() {
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
      return this.sprintEndDate ? new Date(this.sprintEndDate).toLocaleDateString('fr-FR', options) : ''
    },
  },
  watch: {
    sprintDuration() {
      if (this.sprintDuration) {
        this.informations.sprint_duration_days = this.informations.sprint_duration_weeks * 5
      } else {
        this.informations.sprint_duration_days = 0
      }
    },
    sprintEndDate() {
      if (this.sprintDuration) {
        this.informations.date_end = this.sprintEndDate
      }
    },
    userTimeList() {
      this.totalTeamCapacity = 0
      this.userTimeList.forEach((user) => {
        if (user.total_without_scrum) {
          this.totalTeamCapacity += user.total_without_scrum
        }
      })
    },
    sprintStartDate() {
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
      this.dateSprintStart = new Date(this.informations.date_start).toLocaleDateString('fr-FR', options)
    },
  },
  mounted() {
    this.loadListMembers()
    .then(() => {})
    .catch((error) => {
      console.log(error)
    })
  },
  methods: {
    ...mapActions([
      'loadListMembers',
    ]),
    calculateTeamTimes() {
      this.$refs.form_scrum_valid.validate()
      if (this.formScrumValid && this.informations.sprint_duration_days && this.sum_days) {
        let numberDaysReals = 0
        numberDaysReals = parseFloat(this.informations.sprint_duration_days) - parseFloat(this.informations.team_unavailability)
        
        let teamTimes = parseFloat(this.scrum.duration_delivery) + parseFloat(this.scrum.duration_demo) + parseFloat(this.scrum.duration_plannif) + ( parseFloat(this.sum_days) * numberDaysReals)
        this.scrum.team_time = teamTimes.toFixed(2)
      } else {
        this.$awn.warning('Informations manquantes, durée du sprint requise', {})
      }
    },
    addUserTime() {
      this.$refs.form_members_valid.validate()
      if (this.formMembersValid && this.informations.sprint_duration_days) {
        let percent = parseFloat(this.userTime.risk_rate)
        let number = (parseFloat(this.informations.sprint_duration_days) - parseFloat(this.userTime.specific_availability) - parseFloat(this.informations.team_unavailability))
        this.userTime.total = number - ((percent * number) / 100)
        this.userTime.total_without_scrum = this.userTime.total - parseFloat(this.scrum.team_time)
        this.userTimeList.push(this.userTime)
        this.$refs.form_members_valid.resetValidation()
        this.userTime = {
          user: '',
          specific_availability: 0,
          risk_rate: 0,
          prod_time: 0,
          total: 0,
          total_without_scrum: 0,
        }
      } else {
        this.$awn.warning('Informations manquantes, durée du sprint requise', {})
      }
    },
    removeUser(item) {
      let userToRemove = this.userTimeList.findIndex(user => user == item)
      this.userTimeList.splice(userToRemove, 1)
    }
  },
}
</script>

<style scoped lang="scss">
  #contain-members {
  h2 {
    margin-bottom: 5rem;
    color: #ffc12b;
    font-weight: 400;
    letter-spacing: -0.5px;
  }
  a {
    color: #9e9e9e;
    font-size: 0.7rem;
    margin-bottom: 2rem;
  }
  .v-card {
    background-color: #160a28 !important;
    border-radius: 12px;
  }
  .resum-card {
    background-color: #241738 !important;
  }
  .table {

  .theme--dark.v-card {
    background-color: transparent;
  }
  .v-card__title {
    justify-content: flex-end;
  }
  .v-input {
    width: 50%;
    flex: 0 0 auto;
    justify-content: flex-end !important;
  }
  .v-text-field > .v-input__control > .v-input__slot:before {
    border-color: #f1f1f11b !important;
  }
  .v-data-table {
    background-color: transparent !important;
  }
  .data-table {
    box-shadow: none !important;
  }
  tbody {
    tr {
      td:nth-child(1) {
        background-image: url("@/assets/trigram.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 70%;
        width: 1rem;
        color: #150041;
        padding: 1.5rem 1rem !important;
      }
    }
    tr:hover {
      background-color: #f1f1f11b !important;
    }
  }
}
}
</style>