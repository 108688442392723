<template>
  <v-container fluid>
    <v-row id="finished-project">
      <v-col
        v-for="project in finishedProjects"
        :key="project.id"
        class="mb-12 d-flex"
        cols="12"
        lg="4"
        md="4"
      >
        <v-card color="#241738"
          dark
          width="100%"
          height="100%"
          class="mb-12 d-flex flex-column justify-space-between">
          <v-card-title class="text-h6">
            <a @click="consultProject(project.id)">{{ project.label }}</a>
          </v-card-title>
          <v-card-subtitle class="font-italic font-weight-light"
          >Release terminée</v-card-subtitle
          >
          <v-card-text>
            <SprintSlider :release-project="project.archived_release" />
          </v-card-text>
          <v-card-actions class="d-flex flex-wrap justify-space-between mx-2 mt-8">
            <div
              class="contain-participants d-flex">
              <div
                v-for="participants in project.participants" 
                :key="participants.id"
                class="participants">
                <v-btn color="#fff"
                  fab
                  x-small
                  evelation="1"
                  class="mr-1 pa-4">
                  <span class="black--text">
                    {{ participants }}
                  </span>
                </v-btn>
              </div>
            </div>
            <v-btn
              small
              text
              color="#4AD295"
              @click="consultRelease(project.archived_release.id)"
            >
              consulter la release
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SprintSlider from './SprintSlider.vue'

import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'FinishedProjects',
  components: {
    SprintSlider,
  },
  data() {
    return {
      // 
    }
  },
  mounted() {
    
    this.setIsSprintSliderInCards(true)
    this.loadDatas()
  },
  computed: {
    ...mapGetters([
      'getProjects', 
      'getLoader', 
      'getIsSprintSliderInCards',
      'getFinishedProjects',
      ]),
    listProjects() {
      return this.getProjects
    },
    isLoading() {
      return this.isLoading
    },
    isSprintSliderInCards() {
      return this.getIsSprintSliderInCards
    },
    finishedProjects() {
      return this.getFinishedProjects
    },
  },
  methods: {
    ...mapActions(['loadListProjects', 'loadListParticipants', 'setLoadingProject', 'setIsSprintSliderInCards', 'setIsConsultRelease']),
    consultRelease(id) {
      this.setIsConsultRelease(true)
      this.$router.push('/projects/release/' + id)
    },
    consultProject(id) {
      this.$router.push('/projects/' + id)
    },
    loadDatas() {
      this.loadListProjects()
      .then(() => {
        this.setLoadingProject(false)
      })
      .catch((error) => {
        this.setLoadingProject(false)
        console.log(error)
      })
    }
  },
}
</script>

<style scoped lang="scss">
.title-finished-project {
  h2 {
    color: #f1f1f1;
    font-weight: 500;
    letter-spacing: -0.5px;
    margin-bottom: 1.5rem;
  }
  span {
    margin-left: 1rem;
    font-size: 0.7rem;
    text-decoration: underline;
    padding-bottom: 1rem;
    a {
      font-weight: 500;
      color: #9c96a4;
    }
  }
}
#finished-project {

  .v-card {
    border-radius: 12px;
      .contain-participants {
        margin-bottom: 0.5rem;
        .participants {
          .v-btn--fab.v-size--x-small {
            height: 24px;
            width: 24px;
          }
          .pa-4 {
            padding: 14px !important;
          }
        }
      }
    }
  // }
}

@media screen and (max-width: 600px) {
  #finished-project {
    margin: 0  !important;
    .v-card {
      margin: 0 auto;
    }
  }
    h2 {
      margin-left: 2rem;
    }
}
</style>