<template>
  <v-dialog v-model="showMemberForm" max-width="600" persistent>
    <v-form ref="form_member_valid" v-model="formMemberValid" class="dialog-member pa-8">
      <h2 v-if="isMemberEditing">
        Modifier un membre
      </h2>
      <h2 v-else>
        Ajouter un membre
      </h2>
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <v-autocomplete dark
            dense
            label="Nom du membre"
            v-model="member.id_gitlab"
            item-text="user_name"
            item-value="user_id"
            :items="gitlabUsersList"
            :rules="[rules.required]"
            :disabled="isMemberEditing"
            @blur="getMemberInfos()"/>
        </v-col>
        <v-col>
          <v-text-field
            dark
            dense
            label="Job *"
            v-model="member.job"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-space-between align-center footer-dialog-member mt-12"
          cols="12"
          md="12"
          lg="12">
          <p>* Champs obligations</p>
          <div>
            <v-btn
              color="#FFDE8D"
              class="cancel-btn"
              plain
              @click="cancel()"
            >annuler</v-btn
            >
            <v-btn
              v-if="isMemberEditing"
              color="#4AD295"
              class="create-btn"
              plain
              @click="editMember()"
              :loading="loading"
            >modifier</v-btn
            >
            <v-btn
              v-else
              color="#4AD295"
              class="create-btn"
              plain
              :loading="loading"
              @click="addNewMember()"
            >ajouter</v-btn
            >
          </div>

        </v-col>
      </v-row>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import _ from 'lodash'

export default {
  name: 'MembersForm',
  props: {
    memberId : {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      loading: false,
      rules: {
        required: (value) => !!value || 'Champs requis',
      },
      member: {
        fullname: '',
        id_gitlab: 0,
        job: '',
        trigram: '',
      },
      memberSelected: [],
      formMemberValid: false,
    }
  },
  computed: {
    ...mapGetters([
      'getIsMemberEditing',
      'getShowMembersForm',
      'getGitlabUsersList',
    ]),
    showMemberForm() {
      return this.getShowMembersForm
    },
    isMemberEditing() {
      return this.getIsMemberEditing
    },
    gitlabUsersList() {
      return this.getGitlabUsersList
    },
    memberDetails() {
      return this.memberSelected.filter(infoMember => infoMember.user_id === this.member.id_gitlab)[0]
    }
  },
  watch: {
    memberDetails() {
      if (this.memberDetails) {
        this.member.fullname = this.memberDetails.user_name
        this.member.trigram = this.memberDetails.trigram
      }
    },
    showMemberForm() {
      if (this.showMemberForm && this.isMemberEditing && this.memberId) {
        this.loadMember(this.memberId)
        .then((response) => {
          this.member = _.clone(response)
          console.log(response)
        })
        .catch((error) => {
          console.log(error)
        })
      } else {
        this.clearDatas()
      }
    }
  },
  mounted() {
    this.fetchGitlabUsersList()
    .then(() => {})
    .catch((error) => {
      console.log(error)
    })
  },
  methods: {
    ...mapActions([
      'setShowMembersForm',
      'setIsMemberEditing',
      'fetchGitlabUsersList',
      'loadMember',
      'createMember',
      'loadListMembers',
      'updateMember',
    ]),
    loadDatas() {
      this.loadListMembers()
      .then(() => {})
      .catch((error) => {
        console.log(error)
      })
    },
    clearDatas() {
      this.member = {
        fullname: '',
        id_gitlab: 0,
        job: '',
        trigram: '',
      }
      this.$refs.form_member_valid.resetValidation()
    },
    cancel() {
      this.setShowMembersForm(false)
      this.clearDatas()
    },
    getMemberInfos() {
      this.memberSelected = _.clone(this.gitlabUsersList)
    },
    addNewMember() {
      this.$refs.form_member_valid.validate()
      if (this.formMemberValid) {
        this.loading = true
        this.createMember(this.member)
        .then(() => {
          this.loading = false
          this.setShowMembersForm(false)
          this.$awn.success('Membre ajouté avec succès.', {})
          this.loadDatas()
        })
        .catch((error) => {
          console.og(error)
          this.$awn.alert("Une erreur est survenue lors de l'ajout.", {})
          this.loading = false
        })
      } else {
        this.loading = false
        this.$awn.warning('Informations incorrectes.', {})
      }
    },
    editMember() {
      this.$refs.form_member_valid.validate()
      if (this.formMemberValid) {
        this.loading = true
        this.updateMember(this.member)
        .then(() => {
          this.loading = false
          this.setShowMembersForm(false)
          this.$awn.success('Membre modifié avec succès.', {})
          this.loadDatas()
        })
        .catch((error) => {
          this.loading = false
          console.log(error)
          this.$awn.alert('Une erreur est survenue lors de la modification.', {})
        })
      } else {
        this.loading = false
        this.$awn.warning('Informations incorrectes.', {})
      }
    }
  }

}
</script>

<style soped lang="scss">

</style>