<template>
  <v-container fluid>
    <v-row class="text-center">
      <v-col cols="12"
        lg="12"
        md="12"
        sm="12">
        <div id="contain-members" class="d-flex justify-space-between">
          <div class="d-flex align-start flex-column">
            <a href="/">Retour</a>
            <h2>Liste des membres</h2>
          </div>
          <v-btn elevation="1"
            rounded
            @click="addMember()"
          ><v-icon>mdi-plus-circle</v-icon>ajouter un membre</v-btn
          >
        </div>
        <div class="table">
          <Loader v-if="loading"/>
          <v-row v-else class="d-flex justify-center">
            <v-col cols="12" lg="12">
              <v-card class="data-table" dark>
                <v-card-title>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  />
                </v-card-title>
                <v-data-table
                  dark
                  :headers="headers"
                  :items="listMembers"
                  :search="search"
                  :item-key="listMembers.id"
                  hide-default-footer
                  class="elevation-0"
                >
                  <template #[`item.trigram`]="{ item }">
                    <v-chip small color="primary" class="black--text">
                      {{ item.trigram }}
                    </v-chip>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon
                      @click="editMember(item.id)"
                      color="#f2f2f2"
                      small
                      class="mr-2"
                    >mdi-pencil</v-icon
                    >
                    <v-icon
                      small
                      color="#FF7B88"
                      @click="openModalDelete(item.id)"
                    >mdi-delete</v-icon
                    >
                  </template>
                  
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <MembersForm :member-id="memberId" />
    <!-- modale delete -->
    <v-dialog v-model="dialogDelete" max-width="650">
      <div class="dialog-member">
        <v-row class="d-flex justify-center align-center">
          <v-col
            cols="12"
            sm="11"
            md="11"
            class="d-flex flex-column justify-center align-center"
          >
            <p>VOULEZ-VOUS SUPPRIMER CE MEMBRE ?</p>

            <div
              class="d-flex justify-center align-center footer-dialog-project"
            >
              <div class="d-flex">
                <v-btn color="#FF7B88" plain @click="cancel()">
                  annuler
                </v-btn>
                <v-btn color="#4ad295"
                  plain
                  @click="deleteUser()"
                >confirmer</v-btn
                >
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-dialog>
  </v-container>
</template>

<script>
import MembersForm from '@/components/forms/MembersForm.vue'
import { mapGetters, mapActions } from 'vuex'

import Loader from '@/components/parts/Loader.vue'

export default {
  name: 'MembersList',
  components: {
    MembersForm,
    Loader,
  },
  data() {
    return {
      search: '',
      headers: [
        {
          text: '',
          align: 'start',
          filterable: false,
          value: 'trigram',
        },
        { text: 'Personne', value: 'fullname' },
        { text: 'Fonction', value: 'job' },
        { text: 'GitLab_id', value: 'id_gitlab' },
        { text: 'Actions', value: 'actions' },
      ],
      idToDelete: null,
      memberId: null,
      dialogDelete: false,
      rules: {
        required: (value) => !!value || 'Champs requis',
      },
      loading: false,
    }
  },
  mounted() {
    this.loading = true
    this.loadListMembers()
      .then(() => {
        this.loading = false
      })
      .catch((error) => {
        (this.loading = false), console.log(error)
      })  
  },
  computed: {
    ...mapGetters([
      'getListMembers', 
      'getMember', 
      'getLoader', 
      ]),
    listMembers() {
      return this.getListMembers
    },
    memberActual() {
      return this.getMember
    },
    isLoading() {
      return this.getLoader
    },
  },
  methods: {
    ...mapActions([
      'loadListMembers',
      'createMember',
      'deleteMember',
      'updateMember',
      'loadMember',
      'setLoader',
      'setShowMembersForm',
      'setIsMemberEditing',
    ]),
    addMember() {
      this.setIsMemberEditing(false)
      this.memberId = null
      this.setShowMembersForm(true)
    },
    editMember(memberId) {
      this.memberId = memberId
      this.setIsMemberEditing(true)
      this.setShowMembersForm(true)
      console.log(memberId)
    },
    cancel() {
      this.dialogDelete = false
      this.fonctionMember = ''
    },
    openModalDelete(id) {
      this.idToDelete = id
      this.dialogDelete = true
    },
    deleteUser() {
      this.deleteMember(this.idToDelete)
        .then(() => {
          this.$awn.success('Membre supprimé avec succès.', {})
          this.dialogDelete = false
        })
        .catch((error) => {
          this.loading = false
          console.log(error)
          console.log(error.response)
          if (error.response.status === 401) {
            this.$awn.warning('Vous ne pouvez pas supprimer cet utilisateur car il est rattaché à un ou plusieurs projets.', {})
            } else {
              this.$awn.alert('Une erreur est survenue lors de la suppression.', {})
            }
        })
    },
  },
}
</script>

<style scoped lang="scss">
#contain-members {
  .v-btn {
    background: rgb(56, 248, 203);
    background: linear-gradient(
      180deg,
      rgba(56, 248, 203, 1) 0%,
      rgba(74, 210, 149, 1) 100%
    );
    color: #150041;
    font-size: 0.6rem;
    font-weight: 600;
    letter-spacing: -0.5px;
    .v-icon {
      margin-right: 0.5rem;
      color: #150041;
      font-size: 1.2rem;
    }
  }
  h2 {
    margin-bottom: 5rem;
    color: #ffc12b;
    font-weight: 400;
    letter-spacing: -0.5px;
  }
  a {
    color: #9e9e9e;
    font-size: 0.7rem;
    margin-bottom: 2rem;
  }
}
.table {

  .theme--dark.v-card {
    background-color: transparent;
  }
  .v-card__title {
    justify-content: flex-end;
  }
  .v-input {
    width: 50%;
    flex: 0 0 auto;
    justify-content: flex-end !important;
  }
  .v-text-field > .v-input__control > .v-input__slot:before {
    border-color: #f1f1f11b !important;
  }
  .v-data-table {
    background-color: transparent !important;
  }
  .data-table {
    box-shadow: none !important;
  }
  tbody {
    tr {
      td:nth-child(1) {
        background-image: url("../assets/trigram.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 70%;
        width: 1rem;
        color: #150041;
        padding: 1.5rem 1rem !important;
      }
    }
    tr:hover {
      background-color: #f1f1f11b !important;
    }
  }
}
.dialog-member {
  display: flex;
  flex-direction: column;
  height: 50%;
  background-color: #241738;
  padding: 1rem;
  h2 {
    margin-bottom: 2rem;
    color: #ffc12b;
    font-weight: 500;
    letter-spacing: -0.5px;
    font-size: 1.4rem;
  }
  p {
    color: #ff7b88;
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 1rem;
  }
  .footer-dialog-member {
    p {
      color: #9e9e9e;
      font-size: 0.7rem;
    }
    .v-btn {
      letter-spacing: -0.5px;
    }
  }
}

@media screen and (max-width: 960px) {
  #contain-members {
    h2 {
      margin-left: 2rem !important;
      font-size: 1rem;
    }
    a {
      margin-left: 2rem;
    }
  }
  .table {
    margin-left: 2rem !important;
  }
  tbody {
    tr {
      td:nth-child(1) {
        background-size: 90% !important;
        width: 3rem !important;
        margin-left: 1rem;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .v-data-table__mobile-row__cell {
    text-align: left !important;
    margin-left: -1.4rem !important;
  }
}
</style>