<template>
  <div>
    <CircularLoader v-if="loading && !showGraph" />
    <zingchart v-if="!loading && showGraph && chartData.scaleX.labels.length > 0" :data="chartData" />
    <p v-if="chartData.scaleX.labels.length < 1 && !loading" class="text-center mt-12 pt-12 font-italic font-weight-light">
      Il n'y a pas de données <v-icon>mdi-emoticon-sad</v-icon>
    </p>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import _ from 'lodash'

import CircularLoader from '../../components/CircularLoader.vue'

export default {
  name: 'DatasBurndown',
  props: {
    sprint: { type: Object, required: true },
  },
  components: {
    CircularLoader,
  },
  data() {
    return {
      loading: false,
      chartData: {
        type: 'line',
        backgroundColor: '#160a28',
        legend: {
          draggable: true,
          backgroundColor: '#160a28',
          color: 'white',
          header: {
            text: 'Légende',
            backgroundColor: '#f2f2f2',
          },
        },
        scaleX: {
          // set scale label
          label: {
            text: 'Date',
            'font-color': '#f2f2f2',
          },
          labels: [],
          item: {
            fontSize: 12,
            'font-color': '#f2f2f2',
          },
        },
        scaleY: {
          label: {
            text: 'Données Burndown',
            'font-color': '#f2f2f2',
          },
          item: {
            fontSize: 12,
            'font-color': '#f2f2f2',
          },
          markers: [
            {
              type: 'area',
              range: [0, -5],
              backgroundColor: 'red',
            },
          ],
          guide: {
            lineStyle: 'dotted',
            lineColor: '#8B829B',
          },
        },
        plot: {
          animation: {
            effect: 'ANIMATION_FADE_IN',
            method: 'ANIMATION_LINEAR',
            sequence: 'ANIMATION_BY_PLOT',
            speed: 1500,
          },
          marker: {
            'background-color': '#8B829B',
            size: 4,
          },
        },
        series: [
          {
            // plot 1 values, linear data
            values: [],
            text: 'Temps réel',
            lineColor: '#FFC12B',
            maker: {
              backgroundColor: 'black',
              size: '5',
            },
            'legend-item': {
              'font-color': '#f2f2f2',
            },
          },
          {
            // plot 2 values, linear data
            values: [],
            text: 'Temps théorique',
            lineColor: '#4AD295',
            maker: {
              backgroundColor: 'white',
              size: '5',
            },
            'legend-item': {
              'font-color': '#f2f2f2',
            },
          },
        ],
      },
    }
  },
  created() {
    this.setShowGraphDB(false)
  },
  computed: {
    ...mapGetters(['getShowGraphDB']),
    showGraph() {
      return this.getShowGraphDB
    },
  },
  watch: {
    sprint: function() {
      this.loadDatasGraph()
    },
  },
  mounted() {
    this.loadDatasGraph()
  },
  methods: {
    ...mapActions(['loadDataBurndowns', 'loadSprint', 'setShowGraphDB']),
    loadDatasGraph() {
      this.loading = true
      this.loadDataBurndowns(this.sprint.id)
        .then((response) => {
          let dateList = []
          let valuesRealTime = []
          let valuesTheoricTime = []

          response.forEach((element) => {
            let date = element.data_date.split('-')
            let dateFormat = date[2] + '/' + date[1]
            if (_.indexOf(dateList, dateFormat) < 0) {
              dateList.push(dateFormat)
            }
            if (element.is_real === true) {
              valuesRealTime.push(element.data_value)
            } else {
              valuesTheoricTime.push(element.data_value)
            }
          })

          this.chartData.scaleX.labels = dateList
          this.chartData.series[0].values = valuesRealTime
          this.chartData.series[1].values = valuesTheoricTime

          this.loading = false
          this.setShowGraphDB(true)
        })

        .catch((error) => {
          this.loading = false
          console.log(error)
        })
    }
  },
}
</script>

<style>
</style>