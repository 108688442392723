<template>
  <div>
    <CircularLoader v-if="loading && !showGraphCardsAdvancementFront" />
    <zingchart v-if="!loading && showGraphCardsAdvancementFront && !isEmptyDatasGraph" :data="chartData"/>
    <p v-if="isEmptyDatasGraph && !loading" class="text-center my-12 py-12 font-italic font-weight-light">
      Il n'y a pas de données <v-icon>mdi-emoticon-sad</v-icon>
    </p>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CircularLoader from '../CircularLoader.vue'

export default {
  name: 'CardsAdvancementFront',
  props: {
    sprint: { type: Object, required: true },
  },
  components: {
    CircularLoader,
  },
  data() {
    return {
      loading: false,
      chartData: {
        type: 'hbullet',
        width: '75%',
        x: '25%',
        backgroundColor: '#160a28',
        color: 'white',
        legend: {
          draggable: true,
          visible: false,
          backgroundColor: '#160a28',
          color: 'white',
          header: {
            text: 'Légende',
            backgroundColor: '#f2f2f2',
          },
        },
        plot: {
          goal: {
            borderRadius: 50,
            alpha: 1,
            width: 10,
            tooltip: {
              backgroundColor: 'green',
              text: '<span style="font-size:16px;font-weight:bold;">Carte %data-text</span><br>Temps estimé : %g jours',
              rules: [
                {
                  rule: "'%data-text' === 'fermée'",
                  backgroundColor: 'red'
                },
              ],
            },
          },
        },
        scaleX: {
          label: {
            'font-color': '#f2f2f2',
          },
          labels: [],
          item: {
            width: '30%',
            x: 5,
            fontSize: 9,
            textAlign: 'right',
            'font-color': '#f2f2f2',
          },
        },
        scaleY: {
          offsetStart: '15%',
          label: {
            text: 'Temps passé (jours)',
            'font-color': '#f2f2f2',
          },
          labels: [],
          item: {
            fontSize: 12,
            'font-color': '#f2f2f2',
          },
        },
        series: [
          {
            values: [],
            goals: [],
            valueBox: {
              color: 'white',
              text: '%data-custom',
              placement: 'bottom-out',
              fontSize: 9,
            },  
            dataCustom: [], 
            goal: {
              backgroundColor: 'green',
              rules: [
                {
                  rule: "'%data-text' === 'fermée'",
                  backgroundColor: 'red'
                },
              ],
            },
            dataText: [],
            'legend-item': {
              'font-color': '#f2f2f2',
            },
          'background-color': '#FFC12B',  
          },
        ],  
      }
    }
  },
  created() {
    this.setShowGraphCardsAdvancementFront(false)
  },
  computed: {
    ...mapGetters([
      'getGraphCardsAdvancement',
      'getShowGraphCardsAdvancementFront',
      'getSprint',
    ]),
    graphCardsAdvancement() {
      return this.getGraphCardsAdvancement.filter(datas => datas.issues.length > 0)[0]
    },
    showGraphCardsAdvancementFront() {
      return this.getShowGraphCardsAdvancementFront
    },
    isEmptyDatasGraph() {
      if (this.graphCardsAdvancement !== undefined) {
        return this.graphCardsAdvancement.issues.length < 1 ? true : false
      } else {
        return true
      }
    },
  },
  watch: {
    sprint() {
      this.loadDatasGraph()
    }
  },
  mounted() {
    this.loadDatasGraph()
  },
  methods: {
    ...mapActions([
      'fetchGraphCardsAdvancement',
      'setShowGraphCardsAdvancementFront',
    ]),
    loadDatasGraph() {
      this.clearDatasGraph()
      this.loading = true
      this.fetchGraphCardsAdvancement({ id: this.sprint.id, side: 'Front' })
      .then(() => {
        if (!this.isEmptyDatasGraph) {
          this.graphCardsAdvancement.issues.forEach((issue) => {
            this.chartData.series[0].values.push(issue.time_spend)
            this.chartData.series[0].goals.push(issue.time_estimate)
            this.chartData.series[0].dataCustom.push(issue.dev)
            this.chartData.scaleX.labels.push(issue.title)
            if (!issue.is_closed) {
              this.chartData.series[0].dataText.push('ouverte')
            } else {
              this.chartData.series[0].dataText.push('fermée')
            }
          })
          this.loading = false
          this.setShowGraphCardsAdvancementFront(true)
        } else {
          this.loading = false
          this.setShowGraphCardsAdvancementFront(false)
        }
      })
      .catch((error) => {
        this.loading = false
        console.log(error)
      })
    },
    clearDatasGraph() {
      this.chartData = {
        type: 'hbullet',
        width: '75%',
        x: '25%',
        backgroundColor: '#160a28',
        color: 'white',
        legend: {
          draggable: true,
          visible: false,
          backgroundColor: '#160a28',
          color: 'white',
          header: {
            text: 'Légende',
            backgroundColor: '#f2f2f2',
          },
        },
        plot: {
          goal: {
            borderRadius: 50,
            alpha: 1,
            width: 10,
            tooltip: {
              backgroundColor: 'green',
              text: '<span style="font-size:16px;font-weight:bold;">Carte %data-text</span><br>Temps estimé : %g jours',
              rules: [
                {
                  rule: "'%data-text' === 'fermée'",
                  backgroundColor: 'red'
                },
              ],
            },
          },
        },
        scaleX: {
          label: {
            'font-color': '#f2f2f2',
          },
          labels: [],
          item: {
            width: '30%',
            x: 5,
            fontSize: 9,
            textAlign: 'right',
            'font-color': '#f2f2f2',
          },
        },
        scaleY: {
          offsetStart: '15%',
          label: {
            text: 'Temps passé (jours)',
            'font-color': '#f2f2f2',
          },
          labels: [],
          item: {
            fontSize: 12,
            'font-color': '#f2f2f2',
          },
        },
        series: [
          {
            values: [],
            goals: [],
            valueBox: {
              color: 'white',
              text: '%data-custom',
              placement: 'bottom-out',
              fontSize: 9,
            },  
            dataCustom: [], 
            // tooltip: {
            //   backgroundColor: '',
            // },
            goal: {
              // tooltip: {
                backgroundColor: 'green',
              //   text: '<span style="font-size:16px;font-weight:bold;">Carte %data-text</span><br>Temps estimé : %g jours'
              // },
              // backgroundColor: '',
              rules: [
                {
                  rule: "'%data-text' === 'fermée'",
                  backgroundColor: 'red'
                },
              ],
            },
            dataText: [],
            'legend-item': {
              'font-color': '#f2f2f2',
            },
          'background-color': '#FFC12B',  
          },
        ],  
      }
    },
  },
}
</script>

<style>

</style>