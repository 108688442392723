<template>
  <v-container fluid>
    <v-row class="text-center">
      <v-col cols="12"
        lg="12"
        md="12"
        sm="12">
        <div id="add-btn" class="d-flex justify-space-between">
          <div class="d-flex align-start">
            <div class="img-sun"/>
            <div
              class="
                txt-home
                d-flex
                justify-center
                flex-column
                align-start
              "
            >
              <h2>Bonjour,</h2>
              <p>Nous sommes le {{ date }}.</p>
            </div>
          </div>
          <div>
            <v-btn elevation="1"
              rounded
              @click="addNewProject()"
            ><v-icon>mdi-plus-circle</v-icon>ajouter un nouveau projet</v-btn
            >
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'HelloWorld', 
  data() {
    return {
      date: new Date().toLocaleDateString('fr-FR', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      }),
    }
  },
  computed: {
    ...mapGetters([
      'getDialogProjectCreate',
    ])
  },
  mounted() {},
  methods: {
    ...mapActions([
      'setDialogProjectCreate'
    ]),
    addNewProject() {
      this.setDialogProjectCreate(true)
    }
  }
}
</script>
<style scoped lang="scss">
#add-btn {

  text-align: end;

  .img-sun {
    background-image: url("../assets/sun.gif");
    width: 60px;
    height: 60px;
    background-size: 100%;
    background-repeat: no-repeat;

  }
  .txt-home {
    h2,
    p {
      color: #f1f1f1;
      margin-left: 1rem;
    }
  }
}

.v-btn {
    background: rgb(56, 248, 203);
    background: linear-gradient(
      180deg,
      rgba(56, 248, 203, 1) 0%,
      rgba(74, 210, 149, 1) 100%
    );
    color: #150041;
    font-size: 0.6rem;
    font-weight: 600;
    letter-spacing: -0.5px;
    .v-icon {
      margin-right: 0.5rem;
      color: #150041;
      font-size: 1.2rem;
    }
  }

@media screen and (max-width: 960px) {
  .img-sun {
    margin-left: 2rem !important;
  }
}

@media screen and (max-width: 600px) {
  .img-sun {
    margin-left: 2rem !important;
  }
  #add-btn {
    flex-direction: column;
    text-align: center !important;
    margin: 3rem auto !important;
  }
  .txt-home {
    p {
      text-align: start !important;
    }
  }
}
</style>
