import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'

import commonStore from './common'
import projectStore from './projects'
import memberStore from './members'
import releaseStore from './releases'
import sprintStore from './sprints'

Vue.use(Vuex)
Vue.use(VueAxios, axios)

const store = new Vuex.Store({
  modules: {
    commonStore: commonStore,
    projectStore: projectStore,
    memberStore: memberStore,
    releaseStore: releaseStore,
    sprintStore: sprintStore
  }
})

export default store
