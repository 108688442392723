<template>
  <div class="d-flex justify-center my-12">
    <v-progress-circular
      :size="50"
      color="primary"
      indeterminate
    />
  </div>
</template>

<script>
export default {
  name: 'CircularLoader',
}
</script>

<style>

</style>