import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import fr from 'vuetify/lib/locale/fr'

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#FFC12B',
                secondary: '#4AD295',
            },
            dark: {
                primary: '#FFC12B',
                secondary: '#4AD295',
            },
        },
    },
    lang: {
        locales: {
            fr
        },
        current: 'fr',
    }
})
