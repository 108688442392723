<template>
  <v-container id="infos" fluid>
    <v-row class="d-flex" id="contain-sprint-infos">
      <v-col
        cols="12"
        lg="12"
        md="12"
        sm="12"
        class="d-flex justify-space-between"
        :class="{
          'flex-column justify-center align-center':
            !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <v-card dark :class="{ 'my-8': !$vuetify.breakpoint.mdAndUp }">
          <CircularLoader v-if="loading"/>
          <div v-else>
            <v-card-title>
              Paramètre du SPRINT n° {{ activeSprintName }}
            </v-card-title>
            <v-card-subtitle v-if="sprint.date_start && sprint.date_end">
              Date du début {{ sprint.date_start | moment("DD-MM-YYYY") }}
              <br />
              Date de fin {{ sprint.date_end | moment("DD-MM-YYYY") }}
            </v-card-subtitle>
            <v-card-text>
              Indisponibilité de l'équipe complète : {{ sprint.time_unavailability }} jour(s)
              <br />
              Capacité théorique :
              <span>{{ sprint.theoric_capacity }} jours</span>
            </v-card-text>
            <v-card-actions>
              <v-btn
                small
                text
                :loading="isLoading"
                color="#4AD295"
                @click="calculTheoreticalCapacity()"
              >
                recalculer la capacité théorique
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
        <v-card dark>
          <v-card-title class="mb-4">
            Détail par personne
          </v-card-title>
          <CircularLoader v-if="loading"/>
          <v-card-text
            v-else
            class="users mb-2"
            v-for="user in sprint.users"
            :key="user.id"
          >
            <span> {{ user.user.trigram }} </span> {{ user.capacity }} jours
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <div id="graphic">
      <v-row>
        <v-col cols="12" md="12" lg="12">
          <v-card dark>
            <v-card-title> Graphique du SPRINT </v-card-title>
            <v-card-text>
              <DatasBurndown :sprint="currentSprint"/> 
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12" lg="12">
          <v-card dark>
            <v-card-title> Ratio des cartes </v-card-title>
            <v-card-text>
              <RatesCards :sprint="currentSprint"/>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12" lg="12">
          <v-card dark>
            <v-card-title>
              Avancement des cartes front
            </v-card-title>
            <v-card-text>
              <CardsAdvancementFront :sprint="currentSprint"/>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12" lg="12">
          <v-card dark>
            <v-card-title>
              Avancement des cartes back
            </v-card-title>
            <v-card-text>
              <CardsAdvancementBack :sprint="currentSprint" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import _ from 'lodash'

import DatasBurndown from './charts/DatasBurndown.vue'
import RatesCards from './charts/RatesCards.vue'
import CardsAdvancementFront from './charts/CardsAdvancementFront.vue'
import CardsAdvancementBack from './charts/CardsAdvancementBack.vue'
import CircularLoader from './CircularLoader.vue'

export default {
  name: 'SprintInfos',
  props: {
    sprint: { type: Object, required: true },
  },
  components: {
    DatasBurndown,
    RatesCards,
    CardsAdvancementFront,
    CardsAdvancementBack,
    CircularLoader,
  },
  data() {
    return {
      graphicSwitch: false,
      isLoading: false,
    }
  },
  mounted() {
    this.loadRelease(this.$route.params.id)
      .then((response) => { 
        if (this.isConsultRelease) {
          this.setActiveSprintName(_.findLastIndex(response.sprints) + 1)
          this.addSprint(_.last(response.sprints))
        }
      })
      .catch((error) => {
        console.log(error)
      })
  },
  computed: {
    ...mapGetters([
      'getRelease', 
      'getSprint',
      'getActiveSprintName',
      'getIsSprintSliderInCards',
      'getIsConsultRelease',
      'getLoadingSprint',
      ]),
    release() {
      return this.getRelease
    },
    currentSprint() {
      return this.getSprint
    },
    activeSprintName() {
      return this.getActiveSprintName
    },
    isSprintSliderInCards() {
      return this.getIsSprintSliderInCards
    },
    isConsultRelease() {
      return this.getIsConsultRelease
    },
    loading() {
      return this.getLoadingSprint
    },
  },
  methods: {
    ...mapActions([
      'loadRelease',
      'loadSprint',
      'calculateCapacity',
      'loadDataBurndowns',
      'setActiveSprintName',
      'addSprint',
      'setLoadingSprint',
    ]),
    calculTheoreticalCapacity() {
      this.isLoading = true
      this.setLoadingSprint(true)
      this.calculateCapacity(this.currentSprint.id)
        .then(() => {
          this.setLoadingSprint(false)
          this.isLoading = false
          this.$awn.success('Capacité recalculée avec succès.', {})
          this.loadRelease(this.release.id)
            .then(() => {})
            .catch((error) => {
              this.setLoadingSprint(false)
              console.log(error)
            })
        })
        .catch((error) => {
          this.setLoadingSprint(false)
          this.isLoading = false
          console.log(error)
          this.$awn.alert('Une erreur est survenue lors du calcul.', {})
        })
    },
  },
}
</script>

<style lang="scss">
#contain-sprint-infos {
  .v-card {
    width: 540px;
    background-color: #160a28 !important;
    border-radius: 12px;
    .v-card__title {
      color: #f2f2f2 !important;
    }
    .v-card__subtitle {
      color: #8b829b;
      font-weight: 300;
      font-style: italic;
    }
    .v-card__text {
      color: #f2f2f2;
      span {
        color: #ffde8d;
        font-size: 1.1rem;
        padding-left: 0.5rem;
      }
    }
    .users span {
      background-image: url("../assets/trigram.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 70%;
      color: #150041;
      padding: 1rem 0.8rem 0.8rem 1rem;
      font-size: 0.8rem;
      font-weight: 600;
    }
    .v-card__actions {
      display: flex;
      justify-content: flex-end;
      .v-btn {
        letter-spacing: -0.3px;
      }
    }
  }
}
#graphic {
  .v-card {
    margin: 1.5rem 0;
    background-color: #160a28 !important;
    border-radius: 12px;
    .v-card__title {
      color: #f2f2f2 !important;
    }
    .v-card__title:nth-child(2n) {
      color: #ffde8d !important;
      text-transform: uppercase;
      font-size: 0.9rem;

      .v-input--selection-controls {
        padding: 0rem;
        margin: 0 0 0 1rem;
      }
    }
  }
}
</style>