<template>
  <v-container fluid>
    <Loader v-if="loading" />
    <div v-else>
      <v-row v-if="release && release.project" id="contain-release">
        <v-col cols="12"
          lg="12"
          md="12"
          sm="12">
          <div class="mb-12">
            <a href="/">Retour</a>
            <div class="d-flex justify-space-between align-center">
              <h2>
                {{ release.project.label }} <v-chip x-small dark>
                  EN COURS
                </v-chip>
              </h2>
              <v-btn v-if="release.sprints.length > 0"
                elevation="1"
                rounded
                disabled
                class="d-flex justify-end"
                @click="editSprint(currentSprint.id)">
                modifier le sprint
              </v-btn>
            </div>
            <!--  -->
            <v-row class="d-flex justify-space-between">
              <v-col cols="12"
                md="6"
                lg="6">
                <span>Release en cours</span>
                <p v-if="release.date_start && release.date_end">
                  Du {{ release.date_start | moment("DD/MM/YYYY") }} au
                  {{ release.date_end | moment("DD/MM/YYYY") }}
                </p>
                <div v-if="release.sm"
                  class="infoScrumMaster"
                >Scrum master : {{ release.sm.fullname }}</div
                >
              </v-col>
              <v-col 
                cols="12"
                md="6"
                lg="6"
                class="mt-2"
              >
                <div v-if="gitLabProjects.length > 0" class="d-flex justify-end flex-column align-end">
                  <span class="infoScrumMaster white--text" v-for="gitlabProject in gitLabProjects" :key="gitlabProject.id">
                    {{ gitlabProject.project_name + ' - ' + gitlabProject.version }}
                  </span>
                </div>
                <div v-else class="d-flex justify-end flex-column align-end">
                  <span class="infoScrumMaster white--text">
                    Tags non renseignés
                  </span>
                </div>
              </v-col>

            </v-row>
          </div>
          <v-row class="d-flex justify-center mb-12">
            <v-col cols="12"
              lg="8"
              md="8"
              sm="8">
              <SprintSlider
                v-if="currentSprint"
                class="mb-12"
              />
            </v-col>
          </v-row>
          <SprintInfos v-if="currentSprint" :sprint="currentSprint" />
          <div v-else class="d-flex justify-center white--text font-italic">
            AUCUN SPRINT
          </div>
          <SprintsForm
            v-if="currentSprint"
            v-model="openModalSprints"
            :sprint="currentSprint"
            :id="release.id"
          />
        </v-col>
      </v-row>
    </div>

  </v-container>
</template>

<script>
import SprintInfos from '../components/SprintInfos.vue'
import SprintSlider from '../components/SprintSlider.vue'
import SprintsForm from '../components/forms/SprintsForm.vue'
import Loader from '../components/parts/Loader.vue'

import { mapGetters, mapActions } from 'vuex'
import _ from 'lodash'

export default {
  name: 'ReleaseDetails',
  components: {
    SprintInfos,
    SprintSlider,
    SprintsForm,
    Loader,
  },
  data() {
    return {
      gitLabProjects: [],
      loading: false,
    }
  },
  mounted() {
    this.setIsSprintSliderInCards(false)

    this.loading = true
    this.loadRelease(this.$route.params.id)
      .then((response) => {
        this.loadGitLabProjects()
        // this.loadListSprints({ release_id: response.id })
        //   .then(() => {})
        //   .catch((error) => {
        //   console.log(error)
        // })
        if (this.isConsultRelease) {
          this.addSprint(_.last(response.sprints))
        }
      })
      .catch((error) => {
        console.log(error)
        this.$awn.alert(
          "Une erreur est survenue, la release n'existe pas.",
          {}
        )
        this.$router.push('/')
      })
  },
  computed: {
    ...mapGetters([
      'getRelease',
      'getSprint',
      'getOpenDialogSprints',
      'getSprintEditing',
      'getLoader',
      'getIsSprintSliderInCards',
      'getIsConsultRelease',
      'getListSprints',
    ]),
    release() {
      return this.getRelease
    },
    currentSprint() {
      return this.getSprint
    },
    openModalSprints() {
      return this.getOpenDialogSprints
    },
    sprintEditing() {
      return this.getSprintEditing
    },
    isLoading() {
      return this.getLoader
    },
    isSprintSliderInCards() {
      return this.getIsSprintSliderInCards
    },
    isConsultRelease() {
      return this.getIsConsultRelease
    },
    sprintsList() {
      return this.getListSprints
    },
  },
  watch: {
    release() {
      this.loadListSprints({ release_id: this.release.id })
      .then(() => {})
      .catch((error) => {
        console.log(error)
      })
    }
  },
  methods: {
    ...mapActions([
      'loadRelease',
      'loadSprint',
      'loadListSprints',
      'addSprint',
      'openDialogSprints',
      'setSprintEditing',
      'setLoader',
      'setIsSprintSliderInCards',
      'fetchGitlabNameProjectList',
      'fetchVersionGitlabProject',
    ]),
    editSprint() {
      this.openDialogSprints(true)
      this.setSprintEditing(true)
    },
    loadGitLabProjects() {
      this.fetchGitlabNameProjectList(this.$route.params.id)
        .then((response) => {
          response.forEach((project) => {
          this.fetchVersionGitlabProject(project.id)
          .then((responseVersion) => {

            const gitlabProject = {
              project_name: project.project_name,
              version: responseVersion
            }

            this.gitLabProjects.push(gitlabProject)
            this.loading = false
          })
          .catch((error) => {
            console.log(error)
            this.loading = false
          })
          })
        })
        .catch((error) => {
          this.loading = false
          console.log(error)
        })
    }
  },
}
</script>

<style lang="scss" scoped>
#contain-release {
  .v-btn {
    border: 2px solid #4ad295;
    background: transparent;
    color: #4ad295;
    font-size: 0.6rem;
    font-weight: 600;
    letter-spacing: -0.5px;
  }
  .v-btn--disabled {
    background-color: grey;
    border: 2px solid grey;
    color: grey !important;
  }
  a {
    color: #8b829b !important;
    font-size: 0.7rem;
  }
  h2 {
    margin-top: 1rem;
    color: #ffc12b;
    font-weight: 500;
    letter-spacing: -0.5px;
  }
  span {
    color: #f2f2f2;
  }
  p {
    color: #8b829b;
    font-size: 0.9rem;
    font-style: italic;
    font-weight: 300;
    margin-bottom: -0.2rem;
  }
  .infoScrumMaster {
    color: #8b829b;
    font-size: 0.9rem;
    font-style: italic;
    font-weight: 300;
  }
  .v-chip {
    background: rgb(56, 248, 203);
    background: linear-gradient(
      180deg,
      rgba(56, 248, 203, 1) 0%,
      rgba(74, 210, 149, 1) 100%
    );
    color: #241738;
    font-size: 0.6rem;
    font-weight: 600;
    margin-left: 1rem;
  }
}
@media screen and (max-width: 960px) {
  #contain-release {
    margin-left: 1rem;
  }
}
</style>