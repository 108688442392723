<template>
  <v-dialog v-model="openDialogRelease" max-width="800" persistent>
    <div v-if="actualRelease" class="dialog-releases">
      <div>
        <a @click="backward()">Retour</a>
        <div class="mt-2">
          <h2 v-if="editingRelease">
            Modifier une release
          </h2>
          <h2 v-else>
            Créer une release
          </h2>
          <h3 v-if="actualRelease.project">
            Projet : {{ actualRelease.project.label }}
          </h3>
        </div>
        <!-- form -->
        <v-form ref="form_release_valid" v-model="formReleaseValid">
          <v-row>
            <v-col cols="12"
              md="12"
              lg="12"
              class="mt-2">
              <v-text-field
                dark
                dense
                v-model="release.label"
                label="Nom de la release *"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" lg="12">
              <v-select
                dark
                :menu-props="{ dark: true }"
                v-model="release.id_projects_gitlab"
                :items="GitLab"
                item-text="label"
                dense
                item-value="id"
                label="Selectionner le ou les projets GitLab associés"
                multiple
                chips
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"
              lg="6"
              md="6"
              sm="6"
              id="date-picker">
              <v-text-field
                v-model="release.date_start"
                label="Date de début"
                type="date"
                max="2300-01-01"
                dense
                dark
                :disabled="editingRelease"
                class="input-edit-add"
              />
            </v-col>
            <v-col cols="12"
              lg="6"
              md="6"
              sm="6">
              <v-text-field
                v-model="release.date_end"
                label="Date de fin"
                type="date"
                dense
                max="2300-01-01"
                dark
                :disabled="editingRelease"
                class="input-edit-add"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" lg="12">
              <v-select
                dark
                :menu-props="{ dark: true }"
                v-model="release.sm_id"
                :items="listMembers"
                item-text="fullname"
                dense
                item-value="id"
                label="Scrum master"
                chips
              />
            </v-col>
          </v-row>
          <v-row class="mt-6">
            <v-col cols="12"
              lg="12"
              md="12"
              sm="12">
              <v-text-field
                dark
                dense
                type="number"
                v-model="release.nb_sprint"
                label="Nombre de sprint"
                :disabled="editingRelease"
              />
            </v-col>
          </v-row>
        </v-form>
      </div>
      <!-- btn actions -->
      <v-row>
        <v-col cols="12"
          md="12"
          lg="12"
          class="d-flex justify-space-between align-center footer-dialog-releases mt-12">
          <span>* Champs obligations</span>
          <div>
            <v-btn color="primary"
              text
              @click="cancelRelease()"
            >
              annuler
            </v-btn>
            <v-btn
              v-if="editingRelease"
              class="create-btn"
              plain
              @click="editRelease()"
              :loading="isLoading"
            >
              modifier
            </v-btn>
            <v-btn
              v-else
              class="create-btn"
              plain
              @click="addNewRelease()"
              :loading="isLoading"
            >
              sauvegarder
            </v-btn
            >
          </div>
        </v-col>
      </v-row>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
// import _ from 'lodash'

export default {
  name: 'ReleasesForm',
  props: {
    id: { type: Number, required: false, default: null },
    loadDatas: {
      type: Function,
      required: false,
    }
  },
  data() {
    return {
      gitLabList: [],
      formReleaseValid: false,
      release: {
        daily_periodicity: 1,
        date_end: '',
        date_start: '',
        id_projects_gitlab: [],
        label: '',
        nb_sprint: 1,
        project_id: 0,
        sm_id: 0,
      },
      rules: {
        required: value => !!value || 'Champs requis',
      },
      isLoading: false,
    }
  },
  mounted() {
    this.loadListMembers(this.listMembers)
    .then(() => {})
    .catch((error) => { console.log(error) })

    this.loadGitLabList()
    .then(() => {})
    .catch((error) => { console.log(error) })
},

  computed: {
    ...mapGetters([
      'getActualProject',
      'getGitLab',
      'getListMembers',
      'getRelease',
      'getOpenDialogRelease',
      'getReleaseEditing',
      'getDialogProjectCreate',
      'getIdReleaseEditing',
    ]),
    project() {
      return this.getActualProject
    },
    GitLab() {
      return this.getGitLab
    },
    listMembers() {
      return this.getListMembers
    },
    actualRelease() {
      return this.getRelease
    },
    openDialogRelease() {
      return this.getOpenDialogRelease
    },
    editingRelease() {
      return this.getReleaseEditing
    },
    dialogProjectCreate() {
      return this.getDialogProjectCreate
    },
    idReleaseEditing() {
      return this.getIdReleaseEditing
    },
  },

  watch: {
    openDialogRelease() {
      if (this.openDialogRelease) {
        if (this.editingRelease) {
          this.loadRelease(this.idReleaseEditing)
            .then((response) => {
              this.release = {
                daily_periodicity: response.daily_periodicity,
                date_end: response.date_end,
                date_start: response.date_start,
                id_projects_gitlab: response.id_projects_gitlab,
                label: response.label,
                nb_sprint: response.nb_sprint,
                project_id: response.project.id,
                id: response.id
              }
              
              if (response.sm) {
                this.release.sm_id = response.sm.id
              } else {
                this.release.sm_id = 0
              }
              this.release.project_id = response.project.id
            })
            .catch((error) => {
              console.log(error)
            })
        }  else {
        this.clearDatas()
      }
      }
    },
  },
  methods: {
    ...mapActions([
      'loadProject',
      'loadListProjects',
      'loadGitLabList',
      'loadListMembers',
      'createRelease',
      'updateRelease',
      'loadRelease',
      'loadListReleases',
      'setOpenDialogRelease',
      'setReleaseEditing',
      'setDialogProjectCreate',
    ]),
    clearDatas() {
      this.release = {
          daily_periodicity: 1,
          date_end: '',
          date_start: '',
          id_projects_gitlab: [],
          label: '',
          nb_sprint: 1,
          project_id: 0,
          sm_id: 0,
        }
    },
    addNewRelease() {
      this.$refs.form_release_valid.validate()
      if (this.formReleaseValid) {
        this.release.project_id = this.project.id
        this.isLoading = true
        this.createRelease(this.release)
          .then(() => {
            this.isLoading = false
            this.setOpenDialogRelease(false)
            this.$awn.success('Release créée avec succès.', {})
            if (this.loadDatas) {
              this.loadDatas()
            } else {
              this.loadListProjects()
              .then(() => {})
              .catch((error) => { console.log(error) })
            }
          })
          .catch((error) => {
            this.isLoading = false
            this.$awn.alert('Une erreur est survenue lors de la création.', {})
            console.log(error)
          })
      } else {
        this.isLoading = false
        this.$awn.warning('Informations incorrectes', {})
      }
    },

    cancelRelease() {
      this.setOpenDialogRelease(false)
    },

    editRelease() {
      this.$refs.form_release_valid.validate()
      if (this.formReleaseValid) {
        if (this.release) {
          this.isLoading = true
          this.updateRelease(this.release)
            .then(() => {
              this.isLoading = false
              this.setOpenDialogRelease(false)
              this.loadDatas()
              this.$awn.success('Release modifiée avec succès.', {})
            })
            .catch((error) => {
              this.loading = false
              this.isLoading = false
              this.$awn.alert(
                'Une erreur est survenue lors de la modification.',
                {}
              )
              console.log(error)
            })
        }
      } else {
        this.isLoading = false
        this.$awn.warning('Informations incorrectes', {})
      }
    },
    backward() {
      this.setDialogProjectCreate(true)
      this.setOpenDialogRelease(false)
    },
  },
}
</script>

<style lang="scss" scoped>
.v-dialog {
  .dialog-releases {
    display: flex;
    flex-direction: column;
    height: 50%;
    background-color: #241738;
    padding: 2rem;
    h2 {
      color: #ffc12b;
      font-weight: 500;
      letter-spacing: -0.5px;
      font-size: 1.4rem;
    }
    h3 {
      margin-bottom: 2rem;
      color: #f2f2f2;
      font-weight: 500;
      letter-spacing: -0.5px;
      font-size: 1.1rem;
    }
    h4 {
      margin-top: 4rem;
      color: #f2f2f2;
      font-weight: 500;
      letter-spacing: -0.5px;
      font-size: 1.1rem;
    }
    a {
      color: #9e9e9e;
      font-size: 0.7rem;
    }
    p {
      color: #9e9e9e;
      font-size: 0.8rem;
      font-style: italic;
      font-weight: 300;
    }
    .subtitle {
      color: #ffde8d;
      font-weight: 300;
    }
    .footer-dialog-releases {
      // margin-top: 8rem;
      span {
        color: #9e9e9e;
        font-size: 0.7rem;
      }
      .v-btn {
        letter-spacing: -0.5px;
      }
      .cancel-btn {
        color: #ffde8d;
        letter-spacing: -0.5px;
      }
      .create-btn {
        color: #4ad295;
        letter-spacing: -0.5px;
      }
      .archived-btn {
        color: #8b829b;
        letter-spacing: -0.5px;
        padding-left: 0;
      }
    }
  }
}
.theme--dark.v-sheet {
  background-color: #241738 !important;
}
.theme--dark.v-picker__body {
  background-color: #241738 !important;
}
.theme--dark {
  background-color: #241738 !important;
}
.theme--dark.v-picker__body {
  background: #241738 !important;
}
</style>