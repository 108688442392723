<template>
  <v-app id="application">
    <SideBar v-if="userLogged" />
    <Loader v-if="isLoading"/>
    <v-main :class="userLogged ? 'contain' : ''">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import SideBar from './components/parts/SideBar.vue'
import Loader from './components/parts/Loader.vue'
export default {
  name: 'App',
  components: {
    SideBar,
    Loader,
  },
  data() {
    return {
      //
    }
  },
  mounted() {
    const userConnected = this.$cookies.get('access_token')

    if (userConnected) {
      this.setIsLogged(true)
    } else {
      this.setIsLogged(false)
      this.$router.push('/login')
    }
  },
  computed: {
    ...mapGetters(['getLogged', 'getLoader']),
    userLogged() {
      return this.getLogged
    },
    isLoading() {
      return this.getLoader
    }
  },
  methods: {
    ...mapActions(['setIsLogged']),
  },
}
</script>
<style lang="scss">
@import "~vue-awesome-notifications/dist/styles/style.css";

@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,800;0,900;1,100;1,200;1,300;1,500;1,900&display=swap");

#application {
  padding-bottom: 3rem;
  background: radial-gradient(300px 500px at 300px top, #1c0058, #1b0d30);
  font-family: 'Work Sans', sans-serif;
  .contain {
    margin: 1.5em 1.5em 1.5em calc(1.5em + 18.75em);
  }

}
html {
  // Awesome Notification font definition
  #awn-toast-container,
  #awn-popup-wrapper {
    font-family: 'Work Sans', sans-serif;
  }
}
</style>
